@import 'public/styles/sass/abstracts'

.search-form
  position: relative
  padding: 2px 0 2px 34px
  &__input
    border: none
    height: 20px
    background: none
    color: currentColor
    +typoSmallText
    +placeholder-style
      color: currentColor
      opacity: .6
  &:not(.search-form--mobile)
    .search-form
      &__submit
        position: absolute
        top: 0
        left: 0
        cursor: pointer
        color: currentColor
        svg
          +style-icon(24px, currentColor)
  &--mobile
    padding-left: 0
    .search-form
      &__form
        display: flex
      &__input
        flex: 1
        order: -1
        background: $neutrals-grey-1
        padding: 14px 12px
        color: $neutrals-dark
        +typoSmallText
        height: 48px
      &__submit
        margin-left: 16px
        height: 48px