@import 'public/styles/sass/abstracts'

  
.w-container, .w-container-fluid
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding

.w-container
  max-width: $wcont-width

.w-container-inner
  +pagebuilder-container-inner