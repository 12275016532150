@import 'public/styles/sass/abstracts'

.steps
  &__item
    +typoSmallText
    display: flex
    align-items: center
    color: $neutrals-dark
    +until-desktop
      +typoMicro
    &__label
      margin-right: 8px
      &--active
        font-weight: 700
      &--disabled
        color: $neutrals-grey-3
    &__icon
      width: 14px
      height: 14px
      margin-right: 8px
      +color-stroke($neutrals-dark)
      &--disabled
        +color-stroke($neutrals-grey-3)
