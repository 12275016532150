@import 'public/styles/sass/abstracts'

.radio-button
  display: flex
  align-items: center
  .container
    display: block
    position: relative
    padding-left: 29px
    margin-bottom: 22px
    margin-left: 3px
    cursor: pointer
    +typoRegularText
    color: $neutrals-dark
    user-select: none
    &:hover
      color: $neutrals-grey-4
      input ~ .checkmark
        border-color: $neutrals-grey-4
      input ~ .checkmark:after
        background: $neutrals-grey-4
    input
      position: absolute
      opacity: 0
      cursor: pointer
      &:checked 
        & ~ .checkmark
          border: 2px solid $neutrals-dark
        & ~ .checkmark:after
          display: block
    .checkmark
      position: absolute
      top: 0
      left: 0
      height: 18px
      width: 18px
      background-color: transparent
      border-radius: 50%
      border: 2px solid $neutrals-dark
      &:after
        content: ""
        position: absolute
        display: none
        top: 2px
        left: 2px
        width: 10px
        height: 10px
        border-radius: 50%
        background: $neutrals-dark
