@import 'public/styles/sass/abstracts'

.breadcrumb__step
  +typoSmallText
  display: flex
  align-items: center
  color: $neutrals-grey-4
  +mobile
    +typoMicro
  &--white
    color: white
    .breadcrumb__step__icon
      +color-stroke(white)
  &__label
    margin-right: 8px
    &--last
      margin-right: 0
      font-weight: 700
  &__icon
    width: 14px
    height: 14px
    margin-right: 8px
    +color-stroke(currentColor)