@import 'public/styles/sass/abstracts'

.beauty-routine
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  margin-bottom: 100px
  background: $neutrals-grey-1
  padding: 80px 0
  color: $neutrals-dark
  +mobile
    margin-bottom: 50px
    padding: 50px 0
  &__title
    +typoTitle3
    margin-bottom: 50px
    +mobile
      margin-bottom: 40px
  &__steps
    display: flex
    justify-content: center
    +mobile
      flex-direction: column
      margin-bottom: 0
    &__item
      display: flex
      flex-direction: column
      align-items: center
      max-width: 175px
      &:not(:last-child)
        margin-right: 48px
      +mobile
        &:not(:last-child)
          margin-bottom: 40px
          margin-right: 0
      &__image-box
        margin-bottom: 24px
        height: 100px
      &__image
        max-height: 100px
        display: block
        margin: 0 auto
      &__title
        +typoRegularText
        font-weight: 700
        margin-bottom: 4px
      &__description
        +typoSmallText


  