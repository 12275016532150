@import 'public/styles/sass/abstracts'

.form-checkout
  &__steps
    margin-bottom: 50px
    +mobile
      margin-bottom: 30px
  &__spedizione
    margin-bottom: 50px
    +mobile
      margin-bottom: 67px
  &__fatturazione
    margin-bottom: 50px
    +mobile
      margin-bottom: 67px
  &__pagamento
    margin-bottom: 50px
    +mobile
      margin-bottom: 80px
  &__controls
    display: flex
    justify-content: space-between
    align-items: center
