@import 'public/styles/sass/abstracts'

.myaccount-ordini-detail
  &__title
    +typoTitle5
    font-weight: 400
    display: flex
    align-items: center
    margin-bottom: 50px
  &__back
    +typoTitle5
    font-weight: 400
    display: flex
    align-items: center
    margin-bottom: 50px
    &-icon
      margin-right: 10px
      +style-icon(24px, $neutrals-dark)
    &-title-light
      font-weight: 400
  &__status
    margin-bottom: 50px
  &__top-details
    margin-bottom: 50px
    +res-md-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 30px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 30px
  &__top-detail
    &__title
      +typoRegularText
      font-weight: 700
      margin-bottom: 10px
    &__content
      +typoSmallText
  &__info-button
    margin-top: 50px
    +res-sm-max
      display: none
