@import 'public/styles/sass/abstracts'

.myaccount-menu-item
  display: flex
  aling-items: center
  padding: 12px 0
  color: $neutrals-dark
  cursor: pointer
  +res-sm-max
    background: $neutrals-grey-1
    padding: 23px 16px
    margin-bottom: 16px
  &__icon
    width: 24px
    margin-right: 12px
    svg
      +style-icon(24px, currentColor)
  &__arrow
    margin-left: auto
    +res-md-min
      display: none
    svg
      +style-icon(24px, currentColor)
  &__label
    +typoRegularPrompt
    font-weight: 500
  &--active
    color: $accent-default
    