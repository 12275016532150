@import 'public/styles/sass/abstracts'

.trattamento-card
  max-width: 368px // dipende dal contesto, considerare di rimuoverlo
  background-color: $neutrals-grey-1
  +transition(background-color .5s)
  &__content
    padding: 22px
  &__tags
    > *
      margin-bottom: 8px
      &:not(:last-child)
        margin-right: 8px
  &__title
    +typoTitle4
    margin-bottom: 8px
  &__abstract
    +typoRegularText
  &__description
    +typoSmallText
    margin-top: 30px
    margin-bottom: 30px
  &__details
    +typoRegularText
    margin-bottom: 30px
  &__detail-value
    font-weight: 700
  &__buttons
    > *
      width: 100%
      &:not(:last-child)
        margin-bottom: 8px
  &__expand-button
    margin-top: 20px
  
  &__mobile-expansion
    +res-md-min
      display: none
    +res-sm-max
      max-height: 0
      overflow: hidden
      +transition(max-height 1s)
  
  &--expanded
    +res-md-min
      background-color: $neutrals-grey-2
    // L'espansione ha effetto solo nel mobile
    +res-sm-max
      .trattamento-card
        &__mobile-expansion
          max-height: 700px
        &__expand-button
          display: none
