@import 'public/styles/sass/abstracts'

.box-contatti
  background: $neutrals-grey-1
  padding: 50px 0
  &__title
    margin-bottom: 30px
    +typoTitle3
    +res-sm-max
      +typoTitle4
  &__description
    +typoRegularText
  &__cta
    margin-top: 30px