@import 'public/styles/sass/abstracts'

.trattamenti-listing
  &__empty
    +typoRegularText
  &__loading
    margin: 100px 0
    > *
      margin: 0 auto
    +res-sm-max
      margin: 50px 0

.trattamenti-list
  &__block
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(16px)

  &__item
    margin-bottom: 16px
    order: 0
    +res-md-only
      width: 50%
    +res-lg-min
      width: 33.33%
    
    // SUPERHACK per avere il blocco espanso nella posizione corretta
    +res-md-only
      &--order-0, &--order-1
        order: 0
        &+ .trattamenti-list__item--expanded
          order: 1
      &--order-2, &--order-3
        order: 2
        &+ .trattamenti-list__item--expanded
          order: 3
      &--order-4, &--order-5
        order: 4
        &+ .trattamenti-list__item--expanded
          order: 5
    +res-lg-min
      &--order-0, &--order-1, &--order-2
        order: 0
        &+ .trattamenti-list__item--expanded
          order: 1
      &--order-3, &--order-4, &--order-5
        order: 2
        &+ .trattamenti-list__item--expanded
          order: 3

    &--expanded
      +res-md-min
        width: 100%
        max-height: 0
        overflow: hidden
      +res-sm-max
        display: none
      &--open
        +res-md-min
          max-height: 1000px
          +transition(max-height .5s)
      
  .trattamento-card
    margin-left: auto
    margin-right: auto
  