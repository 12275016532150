@import 'public/styles/sass/abstracts'
@mixin mobile
    @media (max-width: 768px)
        @content

.diventa-un-centro-template
    background: $neutrals-grey-1
    &__image
      background-image: url("/img/landing_b2b_image_desktop.jpg")
      background-position: 0 0
      background-repeat: no-repeat
      background-size: cover
      width: 100%
      max-width: 366px

      +mobile
        display: none
        
    
    &__form_title
      +typoTitle4
      text-align: center
      max-width: 377px
      margin: 0 auto 16px
    
    &__form
        margin: 40px 16px
        +mobile
          margin: 0
        textarea
          resize: none
          height: 102px
        .form-contatti
          &__label
            font-family: 'Nunito Sans'
            font-size: 12px
        .form-radio__options > *
          margin-bottom: 0
    
    &__form_container
      position: relative
      display: flex
      flex-direction: row
      margin: -360px 0
      background: #ffffff
      +box-shadow(0px 8px 20px rgba(105, 100, 94, 0.16))
      +wcontainer
      padding-left: 0
      padding-right: 0
      max-width: 950px
      +mobile
        box-shadow: none
        margin: 0 auto
        padding: 30px 16px
    &__button
        margin: 0 auto
        text-align: center
    .generic-step
      &__steps
        justify-content: space-between
        &__item
          width: 30%
          +mobile
            width: auto
    .banner-landing
      &__item
        background: $accent-default
        min-height: 579px
        display: flex
        +mobile
          flex-direction: column
          min-height: 158px
        &__image
          width: 100%