@import 'public/styles/sass/abstracts'

.form-contatti
  max-width: 752px
  margin-left: auto
  margin-right: auto
  &__button
    text-align: center
  &__modal
    text-align: center
    &__titolo
      +typoTitle5
      margin-bottom: 30px
    &__testo
      +typoRegularText
      margin-bottom: 30px
    svg
      width: 36px
      height: 36px
      +color-icon(black)
