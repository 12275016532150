@import 'public/styles/sass/abstracts'

.form-input-password
  padding: 20px 12px 8px
  +typoSmallText
  border: none
  background: $neutrals-grey-1
  width: 100%
  &--with-toggler
    padding-right: 48px
  &__toggler
    position: absolute
    top: 12px
    right: 12px
    cursor: pointer
    svg
      +style-icon(24px, currentColor)