@import 'public/styles/sass/abstracts'

.ordine-datatable
  .datatable-cell__heading
    display: none
  .datatable-row
    +res-sm-max
      padding-bottom: 6px
  .datatable-cell
    +res-sm-max
      margin-bottom: 12px
  &__mobile-title
    +res-md-min
      display: none
  &__codice, &__totale
    +res-sm-max
      .datatable-cell__content
        font-weight: 700
  &__data
    +res-sm-max
      .datatable-cell__content
        +typoSmallText
  &__stato, &__action
    +res-sm-max
      margin-top: 12px