@import 'public/styles/sass/abstracts'

.toggle__container__checked
    position: absolute
    width: 35px
    height: 20px
    left: 5px
    top: 2px
    background: #8F405D
    border-radius: 20px
    &__ball
        position: absolute
        width: 15px
        height: 15px
        left: 18px
        top: 2px
        background: #FFFFFF
        border-radius: 50%

.toggle__container
    position: absolute
    width: 35px
    height: 20px
    left: 5px
    top: 2px
    background: #69645E
    border-radius: 20px
    &__ball
        position: absolute
        width: 15px
        height: 15px
        left: 3px
        top: 2px
        background: #FFFFFF
        border-radius: 50%