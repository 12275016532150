@import 'public/styles/sass/abstracts'

.esito-checkout
  display: flex
  flex-direction: column
  margin-bottom: 300px
  +mobile
    margin-bottom: 30px
  &__title
    +typoTitle4
    margin-bottom: 30px
  &__title-help
    +typoTitle5
    margin-bottom: 10px
  &__disclaimer
    +typoRegularText
    margin-bottom: 50px
    +mobile
      margin-bottom: 30px
  &__link
    font-weight: 700
    color: $color-primary
  &__email
    color: $color-primary
  &__divider
    margin-bottom: 50px
    +mobile
      margin-bottom: 30px
  &__riepilogo
    +mobile
      margin-bottom: 30px
    &__title
      +typoTitle5
      margin-bottom: 30px
    &__section
      display: flex
      width: 100%
      margin-bottom: 50px
      +mobile
        flex-direction: column
        margin-bottom: 30px
      p
        +typoSmallText
        flex-basis: 50%
        +mobile
          &:not(:last-child)
            flex-basis: 100%
            margin-bottom: 30px
      span
        +typoSmallText
        display: block
        &:first-child
          font-weight: 700
          margin-bottom: 10px
          +mobile
            margin-bottom: 7px
        +mobile
          font-weight: 700
  &__cta
    align-self: flex-end
  &__cta-error
    align-self: flex-end
    margin-bottom: 50px
    margin-top: 50px
    +mobile
      margin-top: 0
