@import 'public/styles/sass/abstracts'

.label
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 4px 8px
  +typoRegularText
  font-weight: 700
  height: 30px
  color: white

  +mobile
    height: 24px
    font-size: 12px
    line-height: 16px

  &__primary
    background-color: $accent-default
    
  &__neutral
    background-color: $neutrals-grey-3

  &__black
    background-color: black
