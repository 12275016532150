@import 'public/styles/sass/abstracts'

.reset-password
    margin-bottom: 230px
    &__form
        display: flex
        justify-content: center
        flex-direction: column
        max-width: 368px
        margin-left: auto
        margin-right: auto
        color: $neutrals-dark
    &__notice
        overflow: hidden
        +transition(all $transition-duration)
        &.hidden
            margin-bottom: 0
            max-height: 0
            padding: 0
        &.visible
            max-height: 300px
            margin-bottom: 24px
            padding: 12px 16px
    &__field
        margin-bottom: 24px
    &__disclaimer
        +typoSmallText
        margin-bottom: 24px
        text-align: center
    .divider
        margin-bottom: 24px