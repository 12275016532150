@import 'public/styles/sass/abstracts'
  
.login
  margin-bottom: 220px
  +mobile
    margin-bottom: 50px
  .social-login
    max-width: 368px
    display: flex
    flex-direction: column
    margin-left: auto
    margin-right: auto
    text-align: center
    margin-top: 24px
    .button
      &:not(:last-child)
        margin-bottom: 16px
