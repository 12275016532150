@import 'public/styles/sass/abstracts'

.form-textarea 
  width: 100%
  min-height: 88px
  resize: vertical
  padding: 20px 12px 15px
  +typoSmallText
  border: none
  background: $neutrals-grey-1
  outline: none