@import 'public/styles/sass/abstracts'

.generic-step
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  background: $neutrals-grey-1
  padding: 80px 0 20px 0
  color: $neutrals-dark
  flex-wrap: wrap
  +mobile
    padding: 50px 0 20px 0
  &__content
    +mobile
      text-align: left
  &__container
    max-width: $wcont-width
    margin: 370px 19.5px 0 19.5px
    +mobile
      margin: 0 19.5px
    
  &__title
    +typoTitle3
    margin-bottom: 50px
    +mobile
      margin-bottom: 30px
      text-align: left
      +typoTitle4
  &__steps
    display: flex
    justify-content: center
    flex-wrap: wrap
    +mobile
      flex-direction: column
      margin-bottom: 0
    &__item
      display: flex
      flex-direction: column
      align-items: center
      width: 33%
      margin-bottom: 70px
      &:nth-last-child(-n+3)
        margin-bottom: 30px
      +mobile
        flex-direction: row
        width: auto
        &:not(:last-child)
          margin-bottom: 40px
    
      &__image-box
        margin-bottom: 20px
        +mobile
          min-width: 70px
          margin-right: 16px
          margin-bottom: 0
      &__image
        max-height: 100px
        display: block
        margin: 0 auto
      &__title
        +typoRegularText
        font-weight: 700
        margin-bottom: 4px
      &__description
        +typoSmallText


  