@import 'public/styles/sass/abstracts'

.indirizzo-card 
  padding: 20px 24px
  display: flex
  align-items: center
  background: $neutrals-grey-1
  &__radio
    margin-right: 12px
  &__content
    flex: 1
  &__title
    +typoRegularText
    font-weight: 700
    color: $neutrals-dark
  &__address
    +typoSmallText
    color: $neutrals-dark
  &__edit-button
    margin-left: 12px
    &:not(:disabled)
      cursor: pointer
    svg
      width: 24px
      height: 24px
      +color-icon($neutrals-dark)