@import 'public/styles/sass/abstracts'

.button
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center

  &:hover:not(:disabled)
    cursor: pointer

  &__icon
    svg
      display: block
      +color-icon(currentColor)

  // Tipi
  &--primary
    background-color: $accent-default
    color: white

    &:hover:not(:disabled)
      background-color: $accent-dark

    &:disabled
      background-color: $neutrals-grey-2

  &--secondary
    background-color: transparent
    border: solid 2px $accent-default
    color: $accent-default

    &:hover:not(:disabled)
      border-color: $accent-dark
      color: $accent-dark

    &:disabled
      border-color: $neutrals-grey-2
      color: $neutrals-grey-2
  
  &--secondary-white
    background-color: transparent
    border: solid 2px $color-white
    color: $color-white

    &:hover:not(:disabled)
      border-color: $accent-dark
      color: $accent-dark

    &:disabled
      border-color: $neutrals-grey-2
      color: $neutrals-grey-2

  &--ghost
    border: none
    background-color: transparent
    color: $accent-default

    &:hover:not(:disabled)
      color: $accent-dark

    &:disabled
      color: $neutrals-grey-2

  &--social
    +typoRegularPrompt
    font-weight: 500
    margin-left: auto
    margin-right: auto
    color: white
    width: 100%
    &.facebook
      background: $facebook
      &:hover
        background: $facebook-dark
    &.google
      background-color: $google
      &:hover
        background-color: $google-dark
    .icon
      width: 24px
      height: 24px
      margin-right: 12px
      fill: white

  // Dimensioni
  &--md
    +typoRegularPrompt
    font-weight: 500
    > * 
      &:not(:last-child)
        margin-right: 16px

    &:not(.button--ghost)
      min-height: 48px
      padding: 6px 16px  

    .button
      &__icon
        svg
          width: 24px
          height: 24px

    &.button--responsive 
      +res-sm-max
        +typoSmallPrompt
        font-weight: 500
        > * 
          &:not(:last-child)
            margin-right: 10px

        &:not(.button--ghost)
          padding: 5px 12px
          min-height: 40px

        .button
          &__icon
            svg
              width: 20px
              height: 20px
              
  &--sm
    +typoSmallPrompt
    font-weight: 500
    > * 
      &:not(:last-child)
        margin-right: 10px

    &:not(.button--ghost)
      padding: 5px 12px
      min-height: 40px

    .button
      &__icon
        svg
          width: 20px
          height: 20px
      
