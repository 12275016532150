@import 'public/styles/sass/abstracts'

.menu
  +wcontainer
  &__nav
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    height: $menu-height
    +transition(all $transition-duration linear)
  &__panel
    position: absolute
    left: 0
    right: 0
    top: 100%
    background: $neutrals-grey-1
    padding: 50px 0
    border-top: 1px solid $neutrals-grey-2   
    &.hidden
      display: none
      visibility: hidden

.w-scrolldown
  .menu
    &__nav
      height: 0
