@import 'public/styles/sass/abstracts'

.articolo-magazine-card
  max-width: 365px
  display: block
  margin-left: auto
  margin-right: auto
  &--main
    +res-sm-min
      display: block
      max-width: none
      background: $color-primary
      padding: 16px
      padding-bottom: 0
      color: white
      .articolo-magazine-card
        &__data
          color: white
        &__cta
          color: white
  &__head
    position: relative
    height: 310px
    margin: 0 auto
    overflow: hidden
    +image-cover
  &__image
    z-index: 1
  &__content
    padding: 22px
  &__category
    position: absolute
    top: 20px
    left: 20px
    right: 20px
    +typoTitle6
    text-transform: uppercase
    color: #fff
    z-index: 2
  &__title
    position: absolute
    bottom: 20px
    left: 20px
    right: 20px
    +typoTitle3
    color: #fff
    z-index: 2
  &__abstract
    +typoRegularText
    margin-bottom: 10px
  &__data
    +typoMicro
    color: $neutrals-grey-3
    margin-bottom: 22px
