@import 'public/styles/sass/abstracts'
  
.articolo-detail
  margin-bottom: 80px
  +res-sm-max
    margin-bottom: 50px
  &__top
    background: $neutrals-grey-3
    color: white
    +res-sm-max
      margin-bottom: 50px
  &__breadcrumbs
    margin: 0!important
    padding-top: 40px
    padding-bottom: 40px
    +res-sm-max
      padding-top: 20px
      padding-bottom: 30px
 
  &__head
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
      > *
        width: 50%

    &__image-box
      position: relative
      +image-cover
      +res-md-min
        order: -1
        max-width: 560px 
        margin-bottom: -70px
      +res-sm-max
        margin-left: #{-1 * $wcont-padding}
        margin-right: #{-1 * $wcont-padding}
      +res-lg-min
        height: 800px
      +res-md-max
        height: 500px
    &__image
      height: 100%
     
    &__content
      +res-md-min
        padding-left: 6%
  &__category
    +typoRegularText
    text-transform: uppercase
    margin-bottom: 50px
    +res-sm-max
      +typoMicro
      margin-bottom: 30px
  &__title
    +typoTitle1
    text-transform: uppercase
    margin-bottom: 50px
    +res-sm-max
      +typoTitle2
      margin-bottom: 30px
  &__date
    +typoSmallText
    +res-sm-max
      margin-bottom: 20px
  &__content
    background: white
    padding-top: 150px // i 70px + ulteriori 80px
    margin-bottom: 80px
    +res-sm-max
      margin-bottom: 50px
      padding-top: 0
  &__links
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 80px
    +res-sm-max
      margin-bottom: 50px
    .icon
      fill: none
    &__prev
      .icon
        +rotate(180deg)
    &__next
      &--right
        margin-left: auto
  &__correlati
    &__title
      +typoTitle3
      text-align: center
      color: $neutrals-dark
      margin-bottom: 50px