@import 'public/styles/sass/abstracts'

.listing
  margin-bottom: 80px
  +mobile
    margin-bottom: 50px
  &.desktop
    .listing__filtri--desktop
      max-height: 500px // casuale
  &.mobile
    .listing__filtri--mobile
      +translateX(0)
  &__controls
    display: flex
    align-items: center
    +mobile
      flex-direction: column
      align-items: start
      margin-bottom: 20px
    &__filters
      display: flex
      align-items: center
      color: $color-primary
      height: 48px
      font-weight: 500
      font-size: 16px
      line-height: 24px
      font-family: $font-primary
      cursor: pointer
      margin-right: 20px
      &--desktop
        +mobile
          display: none
      &--mobile
        display: none
        margin-bottom: 10px
        +mobile
          display: flex
      .icon
        width: 24px
        height: 24px
        margin-right: 12px
        +color-icon($accent-default)
    &__chips
      display: flex
      align-items: center
      flex-wrap: wrap
      +mobile
        margin-bottom: -10px // annullare quello delle chips
      & > div
        margin-right: 10px
        +mobile
          margin-bottom: 10px
    &__orders
      display: flex
      align-items: center
      margin-left: auto
      margin-bottom: 10px
      +mobile
        display: none
      label
        font-family: $font-tertiary
        font-size: 16px
        line-height: 22px
        margin-right: 10px
      .form-select
        &__control
          width: 192px
  &__filtri
    &--desktop
      display: flex
      margin-bottom: 53px
      background: $neutrals-grey-1
      max-height: 0
      overflow: hidden
      +transition(all $transition-duration ease-in-out)
      +mobile
        margin-bottom: 0
      .item
        display: flex
        flex-direction: column
        margin-top: 30px
        margin-bottom: 8px // 30 - 22
        &:first-child
          margin-left: 30px
        &:not(:last-child)
          margin-right: 150px
        &__title
          font-family: $font-tertiary
          font-weight: bold
          font-size: 16px
          line-height: 22px
          margin-bottom: 21px
    &--mobile
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 100%
      +translateX(100%)
      background: $neutrals-grey-1
      z-index: 99999
      padding: 54px 16px 20px 16px
      overflow-y: auto
      +transition($transition-duration ease-in-out)
      .icon
        position: absolute
        top: 24px
        right: 20px
        width: 16px
        height: 16px
        +color-icon($neutrals-dark)
      .item
        display: flex
        flex-direction: column
        margin-bottom: 8px // 30 - 22
        &__title
          font-family: $font-tertiary
          font-weight: bold
          font-size: 16px
          line-height: 22px
          margin-bottom: 21px
  &__list
    & > div
      display: flex
      justify-content: center
      margin-bottom: 33px
      +mobile
        margin-bottom: 40px
  &__loader
    display: flex
    justify-content: center
  &__empty
    +typoRegularText
