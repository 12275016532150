@import 'public/styles/sass/abstracts'

.homepage
  &__section
    &:not(:last-child)
      margin-bottom: 100px
      +res-sm-max
        margin-bottom: 80px

.home-prodotti, .home-news
  &__title
    +typoTitle3
    text-align: center
    margin-bottom: 50px
    +res-sm-max
      +typoTitle4

.home-prodotti
  &__slider
    .prodotto-card
      margin-left: auto
      margin-right: auto
  
.home-news
  &__cta-box
    margin-top: 50px
    text-align: center