@import 'public/styles/sass/abstracts'

.stato__ordine
  display: flex
  flex-direction: column
  flex: 1
  max-width: 180px
  &__nav
    position: relative
  &__dot
    display: block
    margin: 0 auto
    border-radius: 50%
    width: 24px
    height: 24px
    background: $accent-default
    font-weight: bold
    color: $accent-default
    text-align: center
    line-height: 20px
    padding: 2px 0
