@import 'public/styles/sass/abstracts'
  
.magazine
  margin-bottom: 80px
  &__categorie
    margin-bottom: 50px
    +res-sm-max
      margin-bottom: 20px
    &__item
      +typoRegularPrompt
      color: $neutrals-grey-4
      padding: 13px 0
      cursor: pointer
      &--active
        color: $color-primary
    .swiper-slide
      max-width: fit-content
  &__articoli
    > *
      padding-top: 16px
      padding-bottom: 16px
      &:not(:first-child)
        border-top: 1px solid $neutrals-grey-2
    +res-sm-min
      display: flex
      flex-wrap: wrap
      > *
        padding-left: 16px
        padding-right: 16px
        position: relative
        &::before
          content: ""
          position: absolute
          top: 16px
          bottom: 16px
          width: 1px
          left: 0
          background: $neutrals-grey-2
        
    +res-lg-min
      > *
        width: 33.33%
        &:nth-child(2)
          border-top: none
        &:nth-child(n+3),
        &:nth-child(3n+2)
          padding-right: 0
        &:nth-child(3n), &:first-child
          padding-left: 0
          &::before
            display: none
    @media screen and (max-width: $res-md-max) and (min-width: $res-sm-min)
      > *
        width: 50%
        &:first-child
          border-top: none
        &:nth-child(2n+1)
          padding-right: 0
        &:nth-child(2n), &:first-child
          padding-left: 0
          &::before
            display: none        
  &__articolo
    &--main
      +res-sm-min
        width: 100%
      +res-lg-min
        width: 66.66%
