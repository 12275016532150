@import 'public/styles/sass/abstracts'

.myaccount-wishlist
  &__list
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    > *
      width: 50%
      margin: 24px 0
      .prodotto-card
        margin-left: auto
        margin-right: auto

  &__empty
    +typoSmallText
