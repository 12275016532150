@import 'public/styles/sass/abstracts'

.faq-page
  &__categories
    max-width: 970px
    margin: 0 auto 80px
    padding: 0 16px
    > *
      &:not(:last-child)
        margin-bottom: 80px
    +res-sm-max
      margin-bottom: 50px

.faq-category
  &__title
    +typoTitle5
    margin-bottom: 40px
  &__faqs
    > *
      &:not(:last-child)
        margin-bottom: 40px