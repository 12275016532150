@import 'public/styles/sass/abstracts'

.form-login
  max-width: 368px
  margin-left: auto
  margin-right: auto
  color: $neutrals-dark
  &__error
    overflow: hidden
    +transition(max-height $transition-duration)
    > *
      margin-bottom: 24px
    &.hidden
      max-height: 0
    &.visible
      max-height: 300px
  .form-field
    margin-bottom: 16px
  &__remember
    display: flex
    justify-content: space-between
    margin-bottom: 24px
  &__submit
    text-align: center
    &__registrati
      +typoRegularText
      margin-bottom: 24px
    &__accedi
      width: 100%
      margin-bottom: 24px
  a
    +typoRegularText
    font-weight: 700
    color: $color-primary
  

    