@import 'public/styles/sass/abstracts'

.progress-bar
  padding-top: 24px // compensa le label visibili - su una riga max
  display: flex
  align-items: center
  &__step
    flex: 1 0 0
    position: relative
    &:first-child
      flex: 0 0 10px
      &::before
        display: none
      &--active
        flex: 0 0 14px
    &::before
      content: ""
      position: absolute
      top: calc(50% - 1px)
      left: 0
      right: 0
      height: 2px
      background: $neutrals-grey-2
      z-index: -1


    &--active, &--past
      &::before
        background: $accent-default
      .progress-bar__step-dot
        background: $accent-default

    &--active
      .progress-bar__step-dot
        width: 14px
        height: 14px

    &:not(:last-child)
      .progress-bar__step-label
        left: calc(100% - 5px)

    &:last-child
      .progress-bar__step-label
        right: 5px
        text-align: right

  &__step-dot
    margin-left: auto
    display: block
    width: 10px
    height: 10px
    +border-radius(50%)
    background: $neutrals-grey-3

  &__step-label
    position: absolute
    bottom: calc(100% + 4px)
    width: 220px
    white-space: nowrap
    text-overflow: ellipsis
    overflow-x: hidden
    +typoSmallText
    color: $accent-default
