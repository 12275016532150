@mixin pagebuilder-video
  position: relative
  // Per garantire una risoluzione 16:9 - per 4:3 usare 75% di padding-bottom
  padding-bottom: 56.25%
  height: 0
  overflow: hidden
  // Spaced
  margin-top: 80px
  margin-bottom: 80px
  +res-sm-max
    margin-top: 50px
    margin-bottom: 50px
  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
