@import 'public/styles/sass/abstracts'

.header
  position: fixed
  top: 0
  left: 0
  right: 0
  background: $color-white
  height: auto
  z-index: 3000
  +transition(#{'background-color .8s, border-color .8s, color .1s'})
  border-bottom: 1px solid $neutrals-grey-2
  +res-sm-max
    height: 50px
  &__top
    +wcontainer
    padding-top: 20px 
    padding-bottom: 18px 
    display: flex
    align-items: center
    +res-md-min
      height: #{$header-height-desktop - $menu-height}
    +res-sm-max
      padding-top: 13px 
      padding-bottom: 12px
    > *
      flex: 1
    &__left--desktop
      +res-sm-max
        display: none
    &__left--mobile
      display: flex
      align-items: center
      +res-md-min
        display: none
      .icon
        width: 24px
        height: 24px
        +color-icon(currentColor)
        cursor: pointer
        &:not(:last-child)
          margin-right: 10px
        
    &__logo
      svg
        display: block
        margin: 0 auto
        width: 100px
        height: 35px
        +color-icon(currentColor)
        +res-sm-max
          width: 73px
          height: 25px

    &__icons
      text-align: right
      > *
        vertical-align: middle
        display: inline-flex
        &:not(:last-child)
          margin-right: 16px
          +res-sm-max
            margin-right: 10px
      svg
        width: 24px
        height: 24px
        +color-icon(currentColor)
        +res-sm-max
          &.icon--profile
            display: none
      .button
        +res-lg-max
          display: none
  
  &__menu
    position: relative // Aggancio tendina
    +res-sm-max
      display: none
  
  &__menu-mobile
    position: fixed
    // top gestito da layout.sass (per toolbar)
    bottom: 0
    left: 0
    width: 100%
    max-width: 400px
    z-index: 6000
    +translateX(-100%)
    +transition(transform .2s linear)
    background: $neutrals-grey-1
    color: $neutrals-dark
    overflow: hidden
    +res-md-min
      display: none
    &--open
      +translateX(0)

  &__search-mobile
    position: absolute
    top: 100%
    left: 0
    right: 0
    z-index: -1
    +translateY(-100%)
    +transition(all .3s linear)
    padding: 8px 16px
    opacity: 0
    background: $color-white
    color: $neutrals-dark
    +res-md-min
      display: none
    &--open
      +translateY(0)
      opacity: 1

  &--transparent
    &:not(:hover)
      background: none
      color: $color-white
      border-bottom-color: transparent


          
