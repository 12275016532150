@import 'public/styles/sass/abstracts'

.prodotti-bundle
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  margin-bottom: 100px
  color: $neutrals-dark
  +mobile
    margin-bottom: 50px
  &__title
    +typoTitle3
    margin-bottom: 50px
    +mobile
      margin-bottom: 40px
  &__list
    display: flex
    justify-content: center
    margin-bottom: 50px
    +mobile
      flex-direction: column
      margin-bottom: 0
    &__item
      display: flex
      flex-direction: column
      align-items: center
      max-width: 272px
      &:not(:last-child)
        margin-right: 112px
      +mobile
        margin-bottom: 40px
        &:not(:last-child)
          margin-right: 0
      &__title
        +typoRegularText
        font-weight: 700
        margin-bottom: 10px
      &__description
        +typoSmallText
