@import 'public/styles/sass/abstracts'

.notification-bar
    display: flex
    align-items: center
    background: $error-default
    color: white
    padding: 10px 16px
    +typoSmallText
    &--grey
        background: $neutrals-grey-3
    &--success
        background: $success-default
    .icon
        +color-icon(currentColor)
        &--alert
            width: 24px
            height: 24px
            margin-right: 16px
        &--check
            width: 24px
            height: 24px
            margin-right: 16px
        &--remove
            width: 18px
            height: 18px
