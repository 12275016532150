@mixin pagebuilder-content
  +typoRegularText
  .custom_text
    +pagebuilder-custom-text

  .quote-container
    +pagebuilder-quote-container

  .quote
    +pagebuilder-quote

  .row
    +pagebuilder-row

  .col-12
    +pagebuilder-col-12
    +res-sm-max
      &:not(:last-child)
        margin-bottom: 50px

  .col-md-3
    +pagebuilder-col-md(3)

  .col-md-4
    +pagebuilder-col-md(4)

  .col-md-6
    +pagebuilder-col-md(6)

  .col-md-8
    +pagebuilder-col-md(8)

  .col-md-9
    +pagebuilder-col-md(9)

  .col-md-12
    +pagebuilder-col-md(12)
  
  .video
    +pagebuilder-video

  .wimage
    +pagebuilder-image

  .block-align
    &--left
      +pagebuilder-block-align-left
    &--center
      +pagebuilder-block-align-center
    &--right
      +pagebuilder-block-align-right
