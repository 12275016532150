@import 'public/styles/sass/abstracts'
  
.quantity-handler
  display: flex
  align-items: center  
  &__input
    +typoRegularText
    max-width: 30px
    border: none
    height: 22px
    text-align: center
    margin: 0
    background: transparent
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      -moz-appearance: textfield
    &:focus
      outline: none
  &__button
    display: flex
    font-size: 14px
    font-weight: bold
    align-items: center
    justify-content: center
    height: 28px
    width: 28px
    cursor: pointer
    svg
      width: 18px
      height: 18px
      +color-icon($neutrals-dark)
