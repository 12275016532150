@import "public/styles/sass/abstracts"

.form-input
  padding: 20px 12px 8px
  +typoSmallText
  border: none
  background: $neutrals-grey-1
  width: 100%
  &--standard
    padding: 10px 12px
