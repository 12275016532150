@import 'public/styles/sass/abstracts'

@mixin centro_radio
    @media (min-width: 280px)
        @content

.form-radio-option
  position: relative
  display: block
  cursor: pointer
  padding-left: 32px
  min-height: 24px

  &__label    
    display: block
    padding-top: 2px
    +typoSmallText
    color: $neutrals-dark

  &__radio 
    position: absolute
    left: -9999px

    &+ .form-radio-option__styledradio
      position: absolute
      top: 0
      left: 0
      width: 24px
      height: 24px
      svg
        +style-icon(24px, $neutrals-dark)

    &:disabled
      &+ .form-radio-option__styledradio
        svg
          +color-icon($neutrals-grey-4)
      &~ .form-radio-option__label
        color: $neutrals-grey-4

.form-radio
  &__description
    font-size: 12px !important
    +typoSmallText
    margin-bottom: 16px
    +res-sm-max
      +typoSmallText
    a
      color: $color-primary
      font-weight: 700

  &__options
    > *
      margin-bottom: 16px
    +centro_radio
      display: flex
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-right: 40px

  &--sm
    .form-radio
      &__description
        +typoSmallText
      

