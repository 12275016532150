@import 'public/styles/sass/abstracts'

.righe-carrello

  &__head
    +typoSmallText
    display: flex
    align-items: center
    font-weight: 700
    margin-bottom: 10px
    +mobile
      display: none

    &__content
      display: flex
      align-items: center
      flex-basis: 50%

    &__divider
      display: none
      +mobile
        display: block

    &__summary
      display: flex
      justify-content: space-between
      align-items: center
      flex-basis: 50%

      & > p
        flex-basis: 20%

        &:last-child
          text-align: right

  &__content
    & > article
      margin: 10px 0
      +mobile
        margin: 20px 0
