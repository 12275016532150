@import 'public/styles/sass/abstracts'

.ordine-stato
  &__title 
    +typoRegularText
    font-weight: 700
    color: $neutrals-grey-4
    margin-bottom: 30px
  &__progress
    margin-bottom: 30px
  &__actions
    display: flex
    justify-content: space-between
    align-items: center