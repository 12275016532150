@import 'public/styles/sass/abstracts'

.checkout
  +res-md-min
    display: flex
    > *
      flex: 1 0 0

  &__main
    +res-md-min
      padding: 50px 56px 50px $wcont-padding
      order: -1
    +res-sm-max
      padding: 30px $wcont-padding
    &__container
      +res-md-min
        max-width: #{calc(#{$wcont-width} / 2)}
        margin-left: auto
    &__image
      display: block
      margin-bottom: 50px
      svg
        display: block
        width: 128px
        height: 44px
      +res-sm-max
        display: none
      &__mobile
        display: none
        padding: 12px 0
        svg
          display: block
          width: 72px
          height: 25px
          margin: 0 auto
        +res-sm-max
          display: block

  &__side
    background: $neutrals-grey-1
    +res-sm-max
      

  &__ordine
    +res-md-min
      max-width: #{calc($wcont-width / 2)}
