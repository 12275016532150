@import 'public/styles/sass/abstracts'

.myaccount-centroestetico-template
  .centro-estetico
    display: flex
    +res-md-max
      flex-wrap: wrap
    &__map
      width: 366px
      max-height: 260px
      position: relative
      margin-left: auto
      +res-md-max
        margin-left: 0
