@import 'public/styles/sass/abstracts'

.form-registrazione
  color: $neutrals-dark
  &__section
    &:not(:first-child)
      padding-top: 50px
      border-top: 1px solid $neutrals-grey-2
      margin-top: 50px
      +res-sm-max
        padding-top: 60px
        margin-top: 60px
  &__privacy
    margin-bottom: 18px
    +res-sm-max
      margin-bottom: 50px
    .form-checkbox
      a
        color: $color-primary
        font-weight: 700

  &__submit
    text-align: center
    .button
      margin-bottom: 32px
      +res-sm-max
        width: 100%
    &__login
      +typoRegularText
      a
        font-weight: 700
        color: $color-primary
  &__error
    +typoLargePrompt
    text-align: center
  

      