@import 'public/styles/sass/abstracts'

.box-marketing-prodotti
  +res-md-min
    padding-bottom: 70px // per includere l'overflow dell'immagine oltre il background nell'occupazione totale del componente
  &__bg
    background: $neutrals-grey-1

  &__container   
    +wcontainer
    +res-md-min
      display: flex
      justify-content: space-between

  &__image-box
    position: relative
    +image-cover
    +res-md-min
      width: 50%
      max-width: 560px
      margin: 70px 0 -70px
      height: 788px
    +res-sm-max
      margin: 0 #{-1 * $wcont-padding}
      height: 515px

  &__content
    padding: 70px 0
    color: $neutrals-dark
    +res-md-min
      width: 45%
    +res-sm-max
      padding: 30px 0
      text-align: center

  &__title
    +typoTitle2
    margin-bottom: 30px

  &__text
    +typoRegularText

  &__cta-box
    margin-top: 30px

  &__slider
    margin-top: 16px
    .prodotto-card
      margin-left: auto
      margin-right: auto

  &--l
    .box-marketing-prodotti
      &__image-box
        order: -1

  