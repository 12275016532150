*,
*::after,
*::before
  margin: 0
  padding: 0
  box-sizing: inherit

ul, ul > li
  list-style: none

button
  background: none
  border: none
  outline: none

img
  max-width: 100%

html
  font-size: 62.5%
  scroll-behavior: smooth

input
  outline: none

main
  display: block
  
video
  width: 100%
  display: block
  outline: none

table
  width: 100%

address
  font-style: normal

a
  outline: none
  text-decoration: none
  color: inherit
  &:hover,
  &:active
    text-decoration: none

body
  box-sizing: border-box
  position: relative
  font-family: $font-primary
  +scrollbar-style
  &.w-noscroll
    overflow: hidden

  &.navigation-open, &.dialog-open
    overflow: hidden

  .custom-content
    position: relative
    &.active
      height: 80vh
      margin-bottom: 3rem
      > .pagebuilder-placeholder
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
  @media (min-width: $wcont-width + 400px)
    .w-container, 
    .w-container-inner
      .custom-content
        > .pagebuilder-placeholder
          width: calc(100% + 218px)  // side width

  .loading
    display: block
    margin-left: auto
    margin-right: auto
    margin-top: calc(50vh - 115px - 20px) // tolgo l'altezza dell'header e la metà di quella del loader
    margin-bottom: calc(50vh + 20px) // aggiungo la metà dell'altezza del loader
    +mobile
      margin-top: calc(50vh - 53px - 20px) // tolgo l'altezza dell'header e la metà di quella del loader

h1, h2, h3, h4, h5, h6
  margin-bottom: 0

@page 
  size: auto
  margin: 0mm

@media print 
  body 
    color-adjust: exact
    -webkit-print-color-adjust: exact

.cms-page
  min-height: 50rem
