@import 'public/styles/sass/abstracts'

.footer
  background: black
  color: white
  padding: 50px 0
  &__row
    &--first
      display: flex
      justify-content: space-between
      margin-bottom: 54px
    &--second
      margin-bottom: 33px
      +res-md-min
        display: flex
        justify-content: space-between
      +res-sm-max
        margin-bottom: 39px
    &--third
      margin-bottom: 57px
      +res-sm-max
        margin-bottom: 37px
  &__logo
    svg
      display: block
      width: 100px
      height: 34.39px
      +color-icon($color-white)
  &__social
    a
      &:not(:last-child)
        margin-right: 16px
      .icon
        width: 24px
        height: 24px
  &__contact
    +typoSmallText
    +res-sm-max
      margin-bottom: 40px
    &__company
      +typoRegularText
      font-weight: 700
      margin-bottom: 3px
  &__menu
    font-weight: 500
    font-size: 14px
    +res-md-min
      display: flex
      > *
        &:not(:last-child)
          margin-right: 24px
    &__item
      display: block
      +res-sm-max
        margin-bottom: 10px
  &__data
    +typoMicro