@import 'public/styles/sass/abstracts'

.prodotto-card
  max-width: 272px
  margin-left: auto
  margin-right: auto
  text-align: center
  +res-sm-max
    max-width: 168px
  &:hover
    +res-xs-min
      .prodotto-card
        &__image-box
          +translateY(-5px)
        &__cta
          opacity: 1
          visibility: visible
  &__top
    display: flex
    //justify-content: space-between
    justify-content: flex-end
    align-items: center
    width: 100%
    margin-bottom: 2px
    min-height: 30px
    position: relative

    &__side
      display: flex
      flex-wrap: wrap
      position: absolute
      max-width: 272px
      +res-sm-max
        max-width: 168px
      z-index: 1
      top: 0px
      left: 0px
      right: 30px

      button
        margin-bottom: 2px
        &:not(:last-child)
          margin-right: 10px

    &__wishlist
      display: flex

      svg
        width: 24px
        height: 24px
        cursor: pointer
        +color-icon(black)

  &__image-box
    display: block
    margin: 0 auto 13px
    +transition($transition-duration)
    +translateY(0)
  &__image
    max-height: 220px
    +res-xs-max
      max-height: 130px
  &__brush
    display: block
    margin: 0 auto 8px
    width: 39px
    height: 7px
  &__title
    +typoTitle5
    margin-bottom: 8px
    +res-sm-max
      +typoTitle6
  &__subtitle
    +typoMicro
    margin-bottom: 16px
    +res-sm-max
      margin-bottom: 10px
  &__price
    +typoRegularText
    font-weight: 700
    margin-bottom: 16px
    &__unavailable
      color: $neutrals-grey-3
    del
      font-weight: 400
  &__cta
    visibility: hidden
    opacity: 0
    +transition($transition-duration)
    +res-sm-max
      display: none

  &--correlato // Uguale alla versione mobile
    padding: 24px 19px 0
    max-width: 168px
    .prodotto-card
      &__title
        +typoTitle6
      &__subtitle
        margin-bottom: 10px
