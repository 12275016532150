// GESTIONE DI TUTTO QUELLO CHE E' IN FIXED
// z-index errori next: 9000 - lascio gli altri sotto
  
.page
  padding-top: $header-height-desktop
  +res-sm-max
    padding-top: $header-height-mobile
  &__description, .custom_text
    ul
      padding-inline-start: 20px
      & > li
        list-style: disc
    ol
      padding-inline-start: 20px
    *
      margin: revert
      padding: revert

.preheader
  top: 0    

.toolbar 
  &~ .page
    padding-top: #{$toolbar-height + $header-height-desktop}
    +res-sm-max
      padding-top: #{$toolbar-height + $header-height-mobile}

body:not(.w-scrolled)

  .preheader.preheader--open ~ .page
    padding-top: #{$preheader-height-desktop + $header-height-desktop}
    +res-sm-max
      padding-top: #{$preheader-height-mobile + $header-height-mobile}

  .toolbar ~ .preheader.preheader--open ~ .page
    padding-top: #{$toolbar-height + $preheader-height-desktop + $header-height-desktop}
    +res-sm-max
      padding-top: #{$toolbar-height + $preheader-height-mobile + $header-height-mobile}

// HOMEPAGE: la home parte da sotto l'header (trasparente) e il prefooter attaccato al footer
.page.homepage
  padding-top: 0
  padding-bottom: 0
  +res-sm-max
    padding-top: 0
    padding-bottom: 0

.toolbar ~ .page.homepage
  padding-top: $toolbar-height
  +res-sm-max
    padding-top: $toolbar-height

body:not(.w-scrolled)

  .preheader.preheader--open ~ .page.homepage
    padding-top: $preheader-height-desktop
    +res-sm-max
      padding-top: $preheader-height-mobile

  .toolbar ~ .preheader.preheader--open ~ .page.homepage
    padding-top: #{$toolbar-height + $preheader-height-desktop}
    +res-sm-max
      padding-top: #{$toolbar-height + $preheader-height-mobile}

// BOX HERO
.box-hero
  height: calc(100vh - #{$header-height-desktop})
  +res-sm-max
    height: calc(100vh - #{$header-height-mobile})

// il box hero in alto nella home si vede anche sotto l'header trasparente
.homepage
  .box-hero
    height: 100vh

.toolbar 
  ~ .page
    .box-hero
      height: calc(100vh - #{$toolbar-height + $header-height-desktop})
      +res-sm-max
        height: calc(100vh - #{$toolbar-height + $header-height-mobile})

    &.homepage
      .box-hero
        height: calc(100vh - #{$toolbar-height})
        +res-sm-max
          height: calc(100vh - #{$toolbar-height})

// HERO LINEA - solo desktop, sotto header
.linea-detail__hero
  +res-md-min
    height: calc(100vh - #{$header-height-desktop + 30px})

.toolbar 
  ~ .page
    .linea-detail__hero
      +res-md-min
        height: calc(100vh - #{$toolbar-height + $header-height-desktop + 30px})


// HEADER + MENU MOBILE 
.header
  top: 0
  .header__menu-mobile
    top: $header-height-mobile
    

.toolbar 
  ~ .header
    top: $toolbar-height
    .header__menu-mobile
      top: #{$toolbar-height + $header-height-mobile}

body:not(.w-scrolled)
  .preheader.preheader--open
    ~ .header
      top: $preheader-height-desktop
      +res-sm-max
        top: $preheader-height-mobile
      .header__menu-mobile
        top: #{$preheader-height-mobile + $header-height-mobile}
      

  .toolbar
    ~ .preheader.preheader--open
      ~ .header
        top: #{$toolbar-height + $preheader-height-desktop}
        +res-sm-max
          top: #{$toolbar-height + $preheader-height-mobile}
        .header__menu-mobile
          top: #{$toolbar-height + $preheader-height-mobile + $header-height-mobile}

// MENU MYACCOUNT MOBILE
.page
  .myaccount-layout__sidebar
    +res-sm-max
      top: $header-height-mobile

.toolbar
  ~ .page
    .myaccount-layout__sidebar
      +res-sm-max
        top: #{$toolbar-height + $header-height-mobile}

// PreHeader
.toolbar
  ~ .preheader.preheader--open
    top: #{$toolbar-height}


// Prodotto sticky bar - Desktop
.prodotto-sticky-bar--visibile
  +res-md-min
    top: $header-height-desktop

.toolbar 
  ~ .prodotto-sticky-bar--visible
      +res-md-min
        top: #{$toolbar-height + $header-height-desktop}

.w-scrolldown
  .prodotto-sticky-bar--visible
    +res-md-min
      top: #{$header-height-desktop - $menu-height}
  .toolbar 
    ~ .prodotto-sticky-bar--visible
        +res-md-min
          top: #{$toolbar-height + $header-height-desktop - $menu-height}

// Prodotto sticky bar - Mobile
.prodotto-sticky-bar--visible
  ~ .footer
    +res-sm-max
      padding-bottom: #{$prodotto-sticky-bar-height-mobile + 50px}

// Layout checkout - senza header e footer
.checkout
  padding-top: 0
  min-height: 100vh
.toolbar 
  ~ .checkout
    padding-top: $toolbar-height
    min-height: calc(100vh - $toolbar-height)

// centri estetici template (desktop) - mappa in tutto lo schermo rimanente (no footer)

.page.centri-estetici-template
  .centri-estetici-form
    +res-md-min
      height: 80px
  .storelocator
    &__tabs
      height: 48px
    &__panel
      &__head
        height: 82px
      &__list
        +res-md-min
          height: calc(100vh - #{$header-height-desktop + 80px + 82px})
    &__map
      +res-md-min
        height: calc(100vh - #{$header-height-desktop + 80px})
      +res-sm-max
        max-height: calc(100vh - $header-height-mobile)
.toolbar 
  ~ .page.centri-estetici-template
    .storelocator
      &__panel
        &__list
          +res-md-min
            height: calc(100vh - #{$toolbar-height + $header-height-desktop + 80px + 82px})
      &__map
        +res-md-min
          height: calc(100vh - #{$toolbar-height + $header-height-desktop + 80px})
        +res-sm-max
          max-height: calc(100vh - #{$toolbar-height + $header-height-mobile})
        