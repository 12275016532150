@import 'public/styles/sass/abstracts'

.lanciomenu
  &__image-box
    position: relative
    +image-cover
    height: 200px
    margin-bottom: 20px
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 20px