@import 'public/styles/sass/abstracts'

.checkbox__container
  display: block
  position: relative
  padding-left: 32px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  input
    display: none

  &__label
    +typoSmallText

  &__checkmark
    position: absolute
    top: 0
    left: 0
    height: 18px
    width: 18px
    background-color: transparent
    border: 2px solid $neutrals-dark
    border-radius: 2px

.checkbox__container:hover input ~ .checkbox__container__checkmark
  border: 2px solid $neutrals-grey-4

.checkbox__container input:checked ~ .checkbox__container__checkmark
  background-color: transparent

.checkbox__container__checkmark:after
  content: "✔"
  position: absolute
  display: none
  font-size: 14px

.checkbox__container input:checked ~ .checkbox__container__checkmark:after
  display: block

.checkbox__container .checkbox__container__checkmark:after
  left: 2px
  top: -3px
