@import 'public/styles/sass/abstracts'

.ordine-riepilogo
  color: $neutrals-dark
  max-width: 464px
  &__section
    &:not(:last-child)
      padding-bottom: 30px
      border-bottom: 1px solid $neutrals-grey-2
      margin-bottom: 30px
    &--prodotti
      &:not(:last-child)
        margin-bottom: 19px
      > *
        &:not(:last-child)
          margin-bottom: 30px
  &__price-row
    display: flex
    justify-content: space-between
    +typoSmallText
    &:not(:last-child)
      margin-bottom: 20px
    &--promo
      font-weight: 700
      color: $accent-default
    &--totale
      +typoRegularText
      font-weight: 700
