@import 'public/styles/sass/abstracts'

.fatturazione-checkout
  &__title
    +typoTitle5
    margin-bottom: 30px
  &__empty
    +typoSmallText
    margin-bottom: 50px
  &__list
    margin-bottom: 30px
    & > div
      margin-bottom: 10px
      +mobile
        margin-bottom: 37px
