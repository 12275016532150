@import 'public/styles/sass/abstracts'

.principi-attivi
  margin-bottom: 100px
  text-align: center
  color: $neutrals-dark
  +res-sm-max
    margin-bottom: 50px
  &.expanded
    .principi-attivi__list--complementari
      max-height: 10000px  // casuale
  &__title
    +typoTitle3
    margin-bottom: 50px
    +res-sm-max
      margin-bottom: 40px
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin-bottom: 10px
      > *
        flex: 0 0 50%
    +res-lg-min
      > *
        flex: 0 0 25%
    &--complementari
      color: $neutrals-grey-4
      max-height: 0
      overflow: hidden
      margin-bottom: 0
      +transition(all $transition-duration ease-in-out)
      .principi-attivi__list__item
        &:not(:last-child)
          margin-right: 51px
          +res-sm-max
            margin-right: 0
            margin-bottom: 40px
    &__item
      padding: 0 8px
      margin-bottom: 40px
      &__image-box
        width: 100px
        height: 100px
        +border-radius(50px)
        margin: 0 auto 20px
        overflow: hidden
      &__title
        +typoRegularText
        font-weight: 700
        margin-bottom: 10px
      &__description
        +typoSmallText
  &__cta
    margin-bottom: 50px
    padding: 13px 0
    +res-sm-max
      margin-bottom: 40px

  