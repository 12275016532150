@import 'public/styles/sass/abstracts'

.box-azienda-highlights
  background: $accent-default
  color: $neutrals-grey-1
  padding: 163px 0 219px 
  +res-md-max
    padding: 50px 34px
  &__content
    +res-lg-min
      display: flex
      > * 
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 6%
    +res-md-max
      > *
        &:not(:last-child)
          margin-bottom: 80px



.box-azienda-highlights-item
  text-align: center
  &__intro
    +typoTitle5
    +res-sm-max
      +typoTitle4
  &__title
    +typoHighlight
    text-transform: uppercase
    +res-sm-max
      +typoHighlightSmall
  &__text
    position: relative
    padding-top: 40px
    +typoRegularText
    &::before
      content: ""
      position: absolute
      top: 20px
      left: 0
      right: 0
      max-width: 150px
      margin: 0 auto
      border-bottom: 1px solid $neutrals-grey-1


@import 'public/styles/sass/abstracts'

.box-azienda-slider
  background: $accent-default
  color: $neutrals-grey-1
  padding: 163px 0 131px
  +res-sm-max
    padding: 50px 0
  &__slider
    .swiper-container
      padding-bottom: 40px

      .swiper-button-prev, .swiper-button-next
        top: auto
        bottom: 0

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next 
        &:after 
          content: $swiper-arrow-left-beige

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev 
        &:after 
          content: $swiper-arrow-right-beige
      
    +res-sm-max
      display: none
  &__content
    +res-md-min
      display: none
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 48px

  
  &--azienda-highlights
    padding-bottom: 131px
    +res-sm-max
      padding-left: 34px
      padding-right: 34px

    .box-azienda-slider
      &__content
        +res-sm-max
          > *
            &:not(:last-child)
              margin-bottom: 80px


.box-azienda-slider-item
  text-align: center
  +res-md-min
    padding: 0 24px
  &__title
    +typoHighlight
    margin-bottom: 20px
    +res-sm-max
      +typoHighlightSmall
      margin-bottom: 10px
  &__subtitle
    +typoTitle4
    margin-bottom: 20px
    +res-sm-max
      +typoTitle5
      margin-bottom: 10px
  &__text
    +typoRegularText

.box-azienda-slider
  &--azienda-highlights
    .box-azienda-slider-item
      &__title
        +typoTitle5
        margin-bottom: 0
        +res-sm-max
          +typoTitle4
          margin-bottom: 0
      &__subtitle
        +typoHighlight
        text-transform: uppercase
        margin-bottom: 0
        +res-sm-max
          +typoHighlightSmall
          margin-bottom: 0
      &__text
        position: relative
        padding-top: 40px
        &::before
          content: ""
          position: absolute
          top: 20px
          left: 0
          right: 0
          max-width: 150px
          margin: 0 auto
          border-bottom: 1px solid $neutrals-grey-1