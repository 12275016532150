@import 'public/styles/sass/abstracts'

.chip
  display: inline-flex
  flex-direction: row
  align-items: center
  padding: 6px 12px
  background: $color-primary
  color: $color-white
  +border-radius(16px)
  cursor: default
  &__label
    +typoSmallText
  &--clickable
    cursor: pointer
  &__icon
    margin-left: 8px
    svg
      +style-icon(16px, currentColor)
