@import 'public/styles/sass/abstracts'

.newsletter-box
  color: $color-white
  width: 368px
  +res-sm-max
    width: 100%
    margin-bottom: 40px
  > .notification-bar--success
    margin-top: 8px
  &__title
    +typoSmallText
    font-weight: 700
  &__form
    display: flex
    flex-wrap: wrap
    .notification-bar
      width: 100%
      margin-top: 8px
  &__field
    margin-top: 8px
    &--email
      width: calc(100% - 76px)
    &--privacy
      width: 100%
      .form-radio
        &__description
          +typoMicro
          margin-bottom: 10px
          a
            color: #fff
        &-option
          &__styledradio
            svg
              +color-icon(#fff)
          &__label
            color: $color-white
    &--submit
      width: 68px
      margin-left: 8px
  &__loading
    padding: 4px 0
    > *
      margin: 0 auto
