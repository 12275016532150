@import 'public/styles/sass/abstracts'

.form-layout
  > *
    margin-bottom: 16px
    &.form-field
      &--space-md
        margin-bottom: 30px
      &--space-lg
        margin-bottom: 40px
      &--space-none
        margin-bottom: 0
  +res-md-min
    display: flex
    flex-wrap: wrap
    +custom-row(16px)
    > *
      width: 50%
      &.form-field--full
        width: 100%
      &.form-field--sm
        width: 25%
