@import 'public/styles/sass/abstracts'

.myaccount-form
  display: flex
  flex-wrap: wrap
  align-items: center
  padding: 30px 0
  &__title
    +typoRegularText
    font-weight: 700
  &__actions
    margin-left: auto
    display: flex
    > *
      &:not(:last-child)
        margin-right: 16px
      +res-sm-max
        flex: 1 0 0
    +res-sm-max
      width: 100%
      margin-top: 20px
  &__content
    width: 100%
    margin-top: 20px
  &--edit
    .myaccount-block
      &__content
        +res-md-min
          order: 2
  &--checkout
    border: 1px solid $neutrals-grey-2
    padding: 20px

.form-centroestetico
  padding: 0px 0
