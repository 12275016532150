@import 'public/styles/sass/abstracts'

.ricerca
  &__empty
    +typoRegularText

  &__categorie
    &__item
      +typoRegularPrompt
      color: $neutrals-grey-4
      padding: 13px 0
      cursor: pointer
      &--active
        color: $color-primary
    .swiper-slide
      max-width: fit-content

  .results
    +res-sm-min
      display: flex
      flex-wrap: wrap
      +custom-row(16px)
      > *
        width: 50%
        margin-top: 50px
    +res-lg-min
      > *
        width: 33.33%
    +res-xs-max
      > *
        margin-top: 16px

  &__empty
    +typoRegularText
    margin: 50px 0