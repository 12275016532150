@import 'public/styles/sass/abstracts'

.seleziona-centro
  &__title
    +typoTitle5
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 5px
  &__disclaimer
    +typoRegularText
    margin-bottom: 30px
    +res-sm-max
      margin-bottom: 50px
  &__filters
    +res-md-min
      display: flex
      justify-content: space-between
      > *
        width: calc(50% - 8px)
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 16px
  &__count
    +typoRegularText
    font-weight: 700
    margin-top: 30px
  &__list
    margin-top: 20px
    max-height: 365px
    overflow-y: auto
    +scrollbar-style