@import 'public/styles/sass/abstracts'

.riga-carrello
  display: flex
  align-items: center

  &__content
    display: flex
    align-items: center
    flex-basis: 50%

  &__summary
    display: flex
    justify-content: space-between
    align-items: center
    flex-basis: 50%

    & > div
      flex-basis: 20%

  &__image
    display: block
    width: 98px
    max-height: 98px
    margin-right: $wcont-padding

  &__title
    +typoTitle6
    text-transform: uppercase
    color: $neutrals-dark

  &__size
    +typoSmallText
    color: $neutrals-dark

  &__price
    text-align: right

  &__fullprice
    +typoSmallText
    text-decoration: line-through
    color: $neutrals-grey-4
  
  &__finalprice
    +typoRegularText
    font-weight: 700
    color: $neutrals-dark
