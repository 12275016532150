@import 'public/styles/sass/abstracts'

.submenu
  +wcontainer
  display: flex
  justify-content: center
  > *
    max-width: 200px
    &:not(:last-child)
      margin-right: 88px
      +res-md-max
        margin-right: 50px
  &__lanciomenu
    width: 200px

  // gestione singole tendine - tutte diverse!
  // Default: 1 colonna + 1 lancio (for man e magazine)
  &__list
    flex: 1
    max-width: 200px 
    
  // Linee e Skincare - multicol  
  &--linee, &--skincare
    .submenu
      &__list
        column-gap: 88px
        +res-md-max
          column-gap: 50px
      &__item
        &--with-children
          margin-bottom: 52px
          -webkit-column-break-inside: avoid
          page-break-inside: avoid
          break-inside: avoid 
          
  // Linee: 2 colonne + 1 lancio
  &--linee
    .submenu
      &__list
        max-width: 488px // largh colonna * num colonne + gap * (num colonne - 1)
        column-count: 2
        +res-md-max
          max-width: 450px
        
  // Skincare: 3 colonne + 1 lancio
  &--skincare
    .submenu
      &__list
        max-width: 776px
        column-count: 3
        +res-md-max
          max-width: 700px
  