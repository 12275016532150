@import '../main'

#nprogress
  pointer-events: none

  .bar
    background: $color-primary
    position: fixed
    z-index: 9000
    top: 0
    left: 0
    width: 100%
    height: 2px
