@import 'public/styles/sass/abstracts'

.pagamento-checkout
  &__title
    +typoTitle5
    margin-bottom: 30px
  &__indirizzi
    display: flex
    width: 100%
    margin-bottom: 50px
    +mobile
      flex-direction: column
      margin-bottom: 30px
    p
      +typoSmallText
      flex-basis: 50%
      +mobile
        &:not(:last-child)
          flex-basis: 100%
          margin-bottom: 30px
    span
      +typoSmallText
      display: block
      &:first-child
        font-weight: 700
        margin-bottom: 10px
        +mobile
          margin-bottom: 7px
      +mobile
        font-weight: 700
  .divider
    margin-bottom: 50px
    +mobile
      margin-bottom: 30px
  &__metodi
    &__item
      margin-bottom: 30px
      +typoSmallText
      &__nome
        margin-left: 29px
        &__label
          margin-right: 20px
      &__description
        +typoMicro
        margin-top: 12px
        padding-left: 29px
      .radio-button
        .checkmark
          position: static
          &::after
            position: static
        .container
          display: flex
          align-items: center
          padding: 0
          margin-bottom: 0
      span
        display: flex
        align-items: center
