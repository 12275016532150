@import 'public/styles/sass/abstracts'

.myaccount-indirizzi
  &__list
    > * 
      &:not(:last-child)
        margin-bottom: 10px
      &:first-child
        padding-top: 0
  &__empty
    +typoSmallText
  &__delete-button
    +res-sm-max
      display: none
  &__add-button
    margin-top: 30px
    +res-sm-max
      width: 100%