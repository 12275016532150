@import "public/styles/sass/abstracts"

.form-field
  &__label
    display: block
    +typoSmallText
    font-weight: 700
    margin-bottom: 10px

  &__children
    +res-md-min
      display: flex
      > * 
        flex: 1 0 0 // rende i campi larghi uguali
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 16px
  &--date
    .form-field
      &__children
        > *
          max-width: 120px
        +res-sm-max
          display: flex
          > *
            flex: 4 0 0
            &:not(:last-child)
              margin-right: 8px
              flex-grow: 3

  &__input-wrap
    position: relative

  &__input-placeholder    
    position: absolute
    top: 14px
    left: 12px
    +typoSmallText
    color: $neutrals-grey-4
    +transition(all .3s)
    pointer-events: none
  
  &__input
    &--placeholder-up
      ~ .form-field__input-placeholder
        +typoMicro
        top: 4px
        font-weight: 700

  &__error, &__input-error
    position: relative
    padding: 4px 0 4px 32px
    margin-top: 8px
    +typoMicro

  &__error-icon, &__input-error-icon
    +style-icon(24px, currentColor)
    position: absolute
    top: 0
    left: 0
