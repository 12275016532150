@import 'public/styles/sass/abstracts'

.datatable

  &__head
    display: flex
    align-items: center
    border-bottom: 1px solid $neutrals-grey-2
    > *
      flex: 1
    +res-sm-max
      display: none

.datatable-headcell
  padding: 10px 8px

.datatable-heading
  display: flex
  justify-content: space-between
  align-items: center
  height: 20px
  &__label
    flex: 1
    +typoSmallText
    color: $neutrals-grey-4
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  
.datatable-error
  +typoSmallText
  color: red

.datatable-row
  border-bottom: 1px solid $neutrals-grey-2
  +res-sm-max
    padding: 10px 0
  &--odd
  &__cells
    display: flex
    +res-sm-max
      flex-wrap: wrap
  &__error
    padding: 4px 8px

.datatable-cell
  padding: 12px 8px
  flex: 1
  &__heading
    margin-bottom: 2px
    +res-md-min
      display: none
  &__content
    +typoRegularText
    color: $neutrals-dark
  &__error
    padding: 4px 0

  // Desktop
  +res-md-min
    &--desktop-cols-1
      flex-basis: 0.83%
      width: 0.83%!important
    &--desktop-cols-2
      flex-basis: 16.66%
      width: 16.66%!important
    &--desktop-cols-3
      flex-basis: 25%
      width: 25%!important
    &--desktop-cols-4
      flex-basis: 33.33%
      width: 33.33%!important
    &--desktop-cols-5
      flex-basis: 41.66%
      width: 41.66%!important
    &--desktop-cols-6
      flex-basis: 50%
      width: 50%!important
    &--desktop-cols-7
      flex-basis: 58.33%
      width: 58.33%!important
    &--desktop-cols-8
      flex-basis: 66.66%
      width: 66.66%!important
    &--desktop-cols-12
      flex-basis: 100%
      width: 100%!important

  // Mobile
  +res-sm-max
    padding-top: 4px
    padding-bottom: 4px
    &--mobile-order-end
      order: -999
    &--mobile-order-end
      order: 999
    &--mobile-cols-1
      flex-basis: 0.83%
      width: 0.83%!important
    &--mobile-cols-2
      flex-basis: 16.66%
      width: 16.66%!important
    &--mobile-cols-3
      flex-basis: 25%
      width: 25%!important
    &--mobile-cols-4
      flex-basis: 33.33%
      width: 33.33%!important
    &--mobile-cols-5
      flex-basis: 41.66%
      width: 41.66%!important
    &--mobile-cols-6
      flex-basis: 50%
      width: 50%!important
    &--mobile-cols-7
      flex-basis: 58.33%
      width: 58.33%!important
    &--mobile-cols-8
      flex-basis: 66.66%
      width: 66.66%!important
    &--mobile-cols-12
      flex-basis: 100%
      width: 100%!important
