@import 'public/styles/sass/abstracts'

.menuitem-mobile
  position: relative
  display: block

  &--main
    +typoRegularPrompt
    font-weight: 500
    padding: 18px 0
    &:not(:last-child)
      border-bottom: 1px solid $neutrals-grey-2

  &--category-item
    +typoSmallPrompt
    padding: 10px 0 10px 32px

  &--with-children
    padding-right: 30px

  &__icon
    position: absolute
    right: 0
    top: calc(50% - 12px)
    cursor: pointer
    svg
      +style-icon(24px, currentColor)
  &__label
    display: block
    margin-right: 24px
    cursor: pointer
