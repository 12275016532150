@import 'public/styles/sass/abstracts'

.paginazione
  display: flex
  justify-content: center
  flex-direction: row
  a
    cursor: pointer
    
  .number
    align-self: center
    margin-right: 10px
    margin-left: 10px
    color: $neutrals-grey-3
    +typoSmallText

    &-active
      color: $color-black

  &-lefticon, &-righticon
    +style-icon(18px, $neutrals-dark)
    &--disabled
      +style-icon(18px, $neutrals-grey-3)

  &-lefticon
    margin-right: 20px
  
  &-righticon
    transform: scaleX(-1)
    margin-left: 20px