@import 'public/styles/sass/abstracts'

.myaccount-block
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 36px
  &__title
    +typoTitle5
  &:not(:last-child)
    padding-bottom: 50px
    border-bottom: 1px solid $neutrals-grey-2
    margin-bottom: 50px
  &__actions
    margin-left: auto
    display: flex
    > *
      &:not(:last-child)
        margin-right: 16px
      +res-sm-max
        flex: 1 0 0
    +res-sm-max
      width: 100%
      margin-top: 20px
