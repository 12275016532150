@import 'public/styles/sass/abstracts'

.prodotto-info
  +res-md-min
    display: flex
    > *
      flex: 1 0 0
  &__image-box
    +res-sm-max
      margin-bottom: 10px
  &__slider
    .swiper-container
      height: 360px
      +res-sm-max
        height: 300px
      .swiper-pagination
        right: auto
        left: 0
  &__slide
    position: relative
    height: 360px
    width: 100%
    +res-sm-max
      height: 300px
    &-image
      position: absolute
      top: 50%
      right: 0
      max-width: calc(100% - 40px)
      max-height: 100%
      +translateY(-50%)
      +res-sm-max
        left: 20px
        right: 20px
        margin: 0 auto
  &__image
    display: block
    margin: 0 0 0 auto
    max-height: 360px
    +res-sm-max
      max-height: 300px
      margin-bottom: 10px
      margin-right: auto
  &__content
    +res-md-min
      padding-left: 10%
    +res-sm-max
      text-align: center
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 35px
    +res-sm-max
      margin-bottom: 20px
      justify-content: center
    > *
      &:first-child
        margin-right: 10px
        +mobile
          margin-right: 6px
  &__category
    +typoMicro
    font-weight: 700
    text-transform: uppercase
    color: $neutrals-dark
  &__brush
    svg
      width: 55.56px
      height: 10px
  &__code
    margin-left: auto
    +typoMicro
    color: $neutrals-grey-3
    &--mobile
      display: none
      margin-left: 0
      align-self: flex-start
      margin-bottom: 10px
      +res-sm-max
        display: block
    &--desktop
      +res-sm-max
        display: none
  &__title
    +typoTitle2
    text-transform: uppercase
    +res-sm-max
      +typoTitle4
  &__subtitle
    +typoRegularText
    font-weight: 700
    margin-top: 8px
    margin-bottom: 30px
    +mobile
      margin-top: 4px
      margin-bottom: 20px
  &__text
    +typoSmallText
    margin-top: 30px
    margin-bottom: 30px
    +mobile
      margin-top: 20px
      margin-bottom: 20px
  &__size
    +typoSmallText
    margin-bottom: 30px
    +mobile
      margin-bottom: 20px
  &__price
    +typoLargeText
    margin-bottom: 30px
    +mobile
      margin-bottom: 20px
  &__fullprice
    text-decoration: line-through
    margin-right: 10px
  &__finalprice
    font-weight: 700
  &__buttons
    display: flex
    align-items: center
    > * 
      &:not(:last-child)
        margin-right: 16px
        +mobile
          margin-right: 10px
      &:last-child
        +mobile
          width: 100%