@import 'public/styles/sass/abstracts'

.video-overlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: $neutrals-dark
  opacity: 0
  pointer-events: none
  z-index: -1
  +transition(opacity .5s)
  &--open
    opacity: 1
    pointer-events: auto
    z-index: 7900
  &__close
    position: absolute
    top: 30px
    right: 30px
    cursor: pointer
    svg
      +style-icon(24px, $color-white)
    +res-sm-max
      top: 16px
      right: 16px

  &__content
    position: absolute
    top: 50%
    +translateY(-50%)
    left: 0
    right: 0
    margin: 0 auto
    padding: 64px 30px
    +res-sm-max
      padding-left: 0
      padding-right: 0

    // Se lo schermo è in orizzontale limito la larghezza per non coprire la X di chiusura
    +landscape
      max-width: calc(100vw - 128px)

  .infinite-loader
    position: absolute
    top: 50%
    +translateY(-50%)
    left: 0
    right: 0
    margin: 0 auto
    +color-icon($color-white)

  &__video-box
    position: relative
    // Per garantire una risoluzione 16:9 - per 4:3 usare 75% di padding-bottom
    padding-bottom: 56.25%
    height: 0
    overflow: hidden
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      
