@import 'public/styles/sass/abstracts'

.form-password
  &__error
    margin-bottom: 0
    max-height: 0
    padding: 0
    overflow: hidden
    +transition(all $transition-duration)
    &.visible
      max-height: 300px
      margin-bottom: 24px
      padding: 12px 16px
