@mixin pagebuilder-quote-container
  margin: 80px 0
  padding: 80px 0 
  background: $accent-default
  +res-sm-max
    margin: 70px 0

@mixin pagebuilder-quote
  position: relative
  +wcontainer
  +typoLargeQuote
  color: $neutrals-grey-1
  text-align: center
  padding-top: 50px
  padding-bottom: 50px
  &::before, &::after
    position: absolute
    left: 0
    right: 0
    margin: 0 auto
  &::before
    content: "“"
    top: 0
  &::after
    content: "”"
    bottom: 0
  +res-sm-max
    +typoRegularQuote
