@import 'public/styles/sass/abstracts'

.prodotto-sticky-bar
  position: fixed
  left: 0
  right: 0
  z-index: 100
  background: $color-white
  overflow: hidden
  opacity: 0
  +box-shadow(-4px 0px 12px rgba(105, 100, 94, 0.1))
  +res-md-min
    top: 0
    height: $prodotto-sticky-bar-height-desktop
    +transition(#{"opacity .5s, top .5s"})
  +res-sm-max
    bottom: 0
    height: $prodotto-sticky-bar-height-mobile
    padding: 16px 0
    +translateY(100%)
    +transition(#{"opacity .5s, transform .5s"})
  &--visible
    opacity: 1
    +res-sm-max
      +translateY(0)
  &__content
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
  &__info
    display: flex
    align-items: center
    justify-content: space-between
    +res-md-min
      width: calc(100% - 290px) // width bottoni + 30px di margine
  &__image-box
    width: 80px
    margin-right: 16px
    +res-sm-max
      display: none
  &__details
    +res-md-min
      max-width: calc(100% - 192px) // 80 immagine + 16 margine + 80 prezzi + 16 margine
    +res-sm-max
      max-width: calc(100% - 90px) // 74 prezzi + 16 margine
  &__title
    margin-bottom: 2px
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis
    +res-md-min
      +typoTitle5
    +res-sm-max
      +typoTitle6
  &__subtitle
    +typoMicro
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis
  &__price
    margin-left: auto
    +res-sm-max
      > *
        display: block
        &:not(:last-child)
          margin-bottom: 4px

  &__fullprice
    margin-right: 20px
    text-decoration: line-through
    color: $neutrals-grey-4
    +res-md-min
      +typoLargeText
      font-weight: 300
    +res-sm-max
      +typoMicro
      font-weight: 300

  &__finalprice
    color: $neutrals-dark
    +res-md-min
      +typoLargeText
      font-weight: 700
    +res-sm-max
      +typoSmallText
      font-weight: 700

  &__buttons
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 10px
      &:last-child
        flex: 1
    +res-md-min
      width: 260px
    +res-sm-max
      margin-top: 10px