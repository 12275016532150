@import 'public/styles/sass/abstracts'

.linea-detail
  &__top
    position: relative
    +res-md-min
      padding: 30px 30px 0
  &__breadcrumbs
    +wcontainer
    z-index: 1
    +res-md-min
      position: absolute
      top: 0
      left: 30px
      right: 30px
      .breadcrumb__step
        color: $color-white!important
  &__hero
    position: relative
    overflow: hidden
    +res-md-min
      // height gestita da layout.sass
    +res-sm-max
      height: 420px
      padding: 0
      margin-top: 0
      margin-bottom: 30px
    &__image
      position: absolute
      top: 0
      height: 100%
      left: -9999px
      right: -9999px
      margin: 0 auto
      max-width: none
      z-index: -1
      +res-md-min
        min-height: 591px // 980 / 1920 * 1158 (wcont-width)
  &__launch
    position: relative
    margin-top: -200px
    margin-bottom: 80px
    padding: 80px 0
    text-align: center
    background: white
    +res-md-min
      max-width: #{$wcont-width - $wcont-padding * 2}
      width: 80%
      margin-left: auto
      margin-right: auto
    +res-sm-max
      margin-top: 0
      padding: 0
    &__brush
      width: 90px
      height: 20px
      margin-bottom: 50px
      +mobile
        margin-bottom: 20px
    &__title
      +typoTitle1
      margin-bottom: 50px
      text-transform: uppercase
      max-width: 752px
      +mobile
        +typoTitle4
        margin-bottom: 20px
    &__description
      +typoRegularText
      color: $neutrals-dark
      max-width: 752px
      +mobile
        +typoMicro
  &__marketing
    margin-bottom: 80px
  &__listing
    margin-bottom: 80px
    &__title
      font-size: 32px
      font-weight: bold
      line-height: 35px
      text-align: center
      margin-bottom: 50px
    &__list
      & > div
        display: flex
        justify-content: center
        margin-bottom: 33px
    &__empty
      +typoRegularText
      text-align: center
      width: 100%
