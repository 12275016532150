@import 'public/styles/sass/abstracts'
  
.box-quote
  +pagebuilder-quote-container
  &__content
    +pagebuilder-quote
  &--sm
    .box-quote
      &__content
        +typoRegularQuote
        +res-sm-max
          +typoSmallQuote
  &--no-quotes
    .box-quote
      &__content
        &::before, &::after
          display: none