@import 'public/styles/sass/abstracts'

.submenu-mobile
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 100
  +translateX(100%)
  +transition(transform .5s)
  padding: 20px 16px
  background: $neutrals-grey-1
  overflow-x: hidden
  overflow-y: auto
  &--open
    +translateX(0)
  &__back
    position: relative
    display: block
    width: 100%
    text-align: left
    padding: 18px 0 18px 32px
    +typoRegularPrompt
    font-weight: 500
    cursor: pointer
    svg
      position: absolute
      top: calc(50% - 12px)
      left: 0
      +style-icon(24px, currentColor)
  &__nav
    > .menuitem-mobile
        margin-bottom: 10px