@import 'public/styles/sass/abstracts'

.form-checkbox
  position: relative
  display: block
  padding-left: 32px
  min-height: 24px
  cursor: pointer
  &--disabled
    color: $neutrals-grey-4
    cursor: default
  &__input
    position: absolute
    left: -9999px

    &+ .form-checkbox__styledinput
      position: absolute
      top: calc(50% - 12px)
      left: 0
      width: 24px
      height: 24px
      svg
        +style-icon(24px, currentColor)

  &__label    
    display: block
    padding-top: 2px
    font-size: 12px !important
    +typoSmallText
    color: currentColor

    a
      color: $color-primary
      font-weight: 700

  &--sm // Per newsletter
    padding-left: 26px
    min-height: 18px
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          top: calc(50% - 9px)
          width: 18px
          height: 18px
          svg
            width: 18px
            height: 18px
      &__label    
        +typoMicro
        padding-top: 0
