@import 'public/styles/sass/abstracts'

.accordion
  &--open
    .accordion
      &__arrow
        +rotate(180deg)
      &__dropdown
        max-height: 1000px
  &__container
    width: auto
    display: flex
    align-items: center
    cursor: pointer
    +res-sm-max
      justify-content: space-between
  &__placeholder
    +typoRegularText
    font-weight: 700
  &__arrow
    margin-left: 24px
    svg
      +style-icon(24px, currentColor)
  &__dropdown 
    overflow: hidden
    max-height: 0
    +transition(max-height .5s)
  &__content
    padding-top: 20px
    +typoRegularText
    color: $neutrals-grey-4
