@import 'public/styles/sass/abstracts'

.prefooter
  background: $neutrals-grey-1
  color: $neutrals-grey-3
  padding: 48px 16px
  &__content
    margin: 0 auto
    max-width: 810px
    > *
      width: 175px
      margin: 0 auto
    +res-sm-min
      display: flex
      justify-content: space-between
    +res-xs-max
      > * 
        &:not(:last-child)
          margin-bottom: 40px

  &__item
    text-align: center
    svg
      display: block
      margin: 0 auto 24px
      width: 71px
      height: 70px
      +color-icon(currentColor)
    &__label
      +typoSmallText
