@import 'public/styles/sass/abstracts'

.centro-estetico-card
  position: relative
  background: #fff
  padding: 16px 16px 16px 47px
  &__chevron
    display: flex
    justify-content: center
    margin-bottom: 20px
    svg
      +style-icon(24px, $neutrals-dark)
      transform: rotateX(-180deg)
  &--map
    padding: 45px 20px 20px
    max-width: 240px
    &::after
      content: ""
      position: absolute
      bottom: -20px
      left: 0
      right: 0
      margin: 0 auto
      width: 0px
      height: 0px
      border: 10px solid transparent
      border-top-color: #fff
  &__close
    position: absolute
    top: 22.74px
    right: 22.59px
    cursor: pointer
    svg
      width: 16px
      height: 16px
      +color-icon($neutrals-dark)

  &__icon
    position: absolute
    top: 16px
    left: 20px
    svg
      width: 15px
      height: 20px
      fill: $accent-default
    &.iconup
      top: 58px
  &__title
    +typoRegularText
    font-weight: 700
    margin-bottom: 6px
  &__address
    +typoSmallText
    margin-bottom: 16px
  &__opening-hours
    +typoMicro
    margin-bottom: 16px
    &-title
      font-weight: 700
      margin-bottom: 6px
  &__buttons
    +res-md-min
      display: none
    > *:not(:last-child)
      margin-right: 8px
  &__phone
    display: flex
    align-items: center
    +typoSmallText
    color: $accent-default
    +res-sm-max
      display: none
    svg
      fill: $accent-default
      width: 16.67px
      height: 16.67px
      margin-right: 9.67px
