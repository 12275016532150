@import 'public/styles/sass/abstracts'

.menuitem
  cursor: pointer
  display: block
  +typoSmallPrompt

  &--category
    font-weight: 600
    margin-bottom: 20px

  &--category-item
    width: 222px !important
    position: relative
    padding: 8px 0  
    +transition(all .5s)
    &:hover
      padding-left: 22px
      .menuitem__icon
        width: 22px   

  &--main
    font-weight: 500
    padding: 0 0 3px
    border-bottom: 3px solid transparent
    &:not(:last-child)
      margin-right: 32px
    &.menuitem--active
      border-bottom-color: $color-black
    font-weight: 500

  &__icon
    +transition(all .5s)
    position: absolute
    left: 0
    top: calc(50% - 7px)
    width: 0
    overflow: hidden
    svg
      +style-icon(14px, currentColor)

  
