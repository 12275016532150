@import 'public/styles/sass/abstracts'

.overlay-modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(#000, .3)
  z-index: 8000
.modal 
  position: fixed
  top: 50%
  +translateY(-50%)
  left: 20px
  right: 20px
  max-width: 630px
  margin: 0 auto
  padding: 70px 30px 50px
  background: #fff
  z-index: 8001
  +res-sm-max
    padding: 60px 20px 20px
  &__close
    position: absolute
    top: 32px
    right: 32px
    cursor: pointer
    svg
      width: 16px
      height: 16px
      +color-icon($neutrals-dark)
    +res-sm-max
      top: 24px
      right: 24px
  &__body
    max-height: calc(100vh - 220px)
    overflow-y: auto
    +scrollbar-style
    &__title
      +typoTitle4
      color: $neutrals-dark
      text-align: center
      margin-bottom: 30px
      +res-sm-max
        margin-bottom: 10px
    &__text
      +typoLargeText
      color: $neutrals-dark
      text-align: center
      +res-sm-max
        margin-bottom: 10px


  