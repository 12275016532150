/* Colors */
$neutrals-dark: #363636
$neutrals-grey-4: #69645E
$neutrals-grey-3: #BAB2AA
$neutrals-grey-2: #E5E1DD
$neutrals-grey-1: #FAF8F6
$accent-dark: #6C2F46
$accent-default: #8F405D
$accent-light: #D97197
$error-dark: #A83932
$error-default: #DE635B
$error-light: #F39D97
$success-dark: #54A06E
$success-default: #74CF93
$success-light: #9CECB7
$warning-dark: #D3952D
$warning-default: #FFBB38
$warning-light: #FFE691
$facebook: #3B5998
$facebook-dark: #2b3c60
$google: #4285F4
$google-dark: #2871EC

$color-black: #000000
$color-white: #ffffff

$color-primary: $accent-default

/* Fonts */
$font-primary: 'Prompt', sans-serif
$font-secondary: 'Playfair Display', serif
$font-tertiary: 'Nunito Sans', sans-serif

/* Resolutions */
$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px

$wcont-width: 1158px
$wcont-inner-width: 784px
$wcont-padding: 16px

$transition-duration: 300ms

$toolbar-height: 50px
$header-height-mobile: 50px
$header-height-desktop: 120px
$menu-height: 45px
$prodotto-sticky-bar-height-desktop: 80px
$prodotto-sticky-bar-height-mobile: 129px
$preheader-height-desktop: 40px
$preheader-height-mobile: 50px

$swiper-chevron-left: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNiAyMEw4IDEyTDE2IDQiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPg0KPC9zdmc+DQo=')
$swiper-chevron-right: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik04IDRMMTYgMTJMOCAyMCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIi8+DQo8L3N2Zz4NCg==')
$swiper-arrow-left-beige: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPGxpbmUgeDE9IjIwLjYzMTEiIHkxPSIxMS41IiB4Mj0iNC42MzExIiB5Mj0iMTEuNSIgc3Ryb2tlPSIjRkFGOEY2IiBzdHJva2Utd2lkdGg9IjIiIC8+DQogIDxwYXRoIGQ9Ik05LjEzMTEgMTZMNC42MzExIDExLjVMOS4xMzExIDciIHN0cm9rZT0iI0ZBRjhGNiIgc3Ryb2tlLXdpZHRoPSIyIiAvPg0KPC9zdmc+')
$swiper-arrow-right-beige: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxsaW5lIHgxPSI0IiB5MT0iMTEuNSIgeDI9IjIwIiB5Mj0iMTEuNSIgc3Ryb2tlPSIjRkFGOEY2IiBzdHJva2Utd2lkdGg9IjIiLz4NCjxwYXRoIGQ9Ik0xNS41IDdMMjAgMTEuNUwxNS41IDE2IiBzdHJva2U9IiNGQUY4RjYiIHN0cm9rZS13aWR0aD0iMiIvPg0KPC9zdmc+DQo=')

  