@import 'public/styles/sass/abstracts'

.cart-counter
  position: relative
  width: 24px
  height: 100%
  display: inline-block
  &__icon
    display: block
    width: 100%
    height: 100%
    +color-icon(currentColor)
  &__num
    position: absolute
    left: 0
    right: 0
    top: calc(62.5% - 6px) // proporzione sull'icon - Metà line-height
    display: block
    font-family: $font-tertiary
    font-size: 9px
    font-weight: 700
    line-height: 12px
    text-align: center
