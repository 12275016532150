@import 'public/styles/sass/abstracts'

.centro-list-item
  color: $neutrals-dark
  &__title
      +typoRegularText
      font-weight: 700
      margin-bottom: 2.5px
  &__address
    +typoSmallText
  .radio-button 
    .container   
      .checkmark
        top: 30%