@import 'public/styles/sass/abstracts'

.box-help
  background: $accent-default
  color: $color-white
  padding: 80px $wcont-padding
  &__container
    max-width: 600px
    margin: 0 auto
    +res-md-min
      display: flex
      align-items: center
      justify-content: center
  &__icon
    width: 100px
    margin-right: 32px
    svg
      +style-icon(100px, currentColor)
    +res-sm-max
      padding-bottom: 20px
      margin: 0 auto 20px
      border-bottom: 1px solid $color-white
  &__content
    +res-sm-max
      text-align: center
  &__title
    +typoTitle5
    margin-bottom: 8px
    +res-sm-max
      +typoRegularPrompt
      font-weight: 600
  &__description
    +typoRegularPrompt
    +res-sm-max
      +typoSmallPrompt