@import 'public/styles/sass/abstracts'
  
.prodotto-detail
  &__breadcrumbs
    margin-bottom: 100px
    +mobile
      margin-bottom: 50px
  .prodotto-info
    margin-bottom: 100px
  &__marketing
    margin-bottom: 100px
    +mobile
      margin-bottom: 50px
  &__correlati
    margin-bottom: 100px
    +mobile
      margin-bottom: 50px
    &__title
      +typoTitle3
      text-align: center
      color: $neutrals-dark
      margin-bottom: 50px