@import 'public/styles/sass/abstracts'

.box-marketing-2
  &__bg
    background: $neutrals-grey-1
 
  &__container
    +wcontainer
    +res-md-min
      display: flex
      justify-content: space-between
      align-items: center

  &__image-box
    position: relative
    +image-cover
    +res-md-min
      margin: -70px 0 70px
      width: 50%
      max-width: 560px
      height: 800px
    +res-sm-max
      margin: 0 #{-1 * $wcont-padding}
      height: 515px

  &__content
    padding: 30px 0 
    +res-md-min    
      width: 45%
    +res-sm-max
      text-align: center

  &__cta
    margin-top: 30px
    +res-sm-max
      margin-bottom: 40px

  // Varianti
  &--bg-white
    .box-marketing-2
      &__bg
        background: $color-white
  
  &--image-v-top
    .box-marketing-2
      &__title
        bottom: 0

    +res-md-min
      &.box-marketing-2--padded
        padding-top: 70px
      .box-marketing-2
        &__image-box
          margin-top: -70px
          margin-bottom: 70px

  &--image-v-bottom
    .box-marketing-2
      &__title
        top: 0

    +res-md-min
      &.box-marketing-2--padded
        padding-bottom: 70px
      .box-marketing-2
        &__image-box
          margin-top: 70px
          margin-bottom: -70px

  &--image-h-right
    +res-md-min
      .box-marketing-2
        &__content
          order: -1

  &--content-default
    .box-marketing-2
      &__title
        +typoTitle2
        margin-bottom: 20px
        +res-sm-max
           +typoTitle5
           margin-bottom: 30px
      &__text
        +typoRegularText
        +res-md-max
          +typoSmallText
          
  &--content-highlight

    .box-marketing-2
      &__title
        position: absolute
        left: 0
        right: 0
        padding: 40px
        +typoTitle1
        color: $color-white
        text-transform: uppercase

        +res-md-max
          +typoTitle3
          padding: 20px

      &__text
        +typoLargePrompt
        color: $accent-default
        +res-md-max
          +typoRegularPrompt


    
