@import 'public/styles/sass/abstracts'

.pagebuilder-slider
  position: relative
  padding-left: 60px
  padding-right: 30px // compensazione ottica: nella versione desktop la slide a destra è più piccola e sembra più distante
  +res-sm-max
    padding-left: 32px
    padding-right: 32px
  overflow: hidden
  // Spaced
  margin-top: 80px
  margin-bottom: 80px
  +res-sm-max
    margin-top: 50px
    margin-bottom: 50px
  &__nav
    position: absolute
    top: calc(50% - 12px)
    z-index: 10
    svg
      +style-icon(24px, $neutrals-grey-4)
    &--prev
      left: 16px
      cursor: pointer
      +res-sm-max
        left: 0
    &--next
      right: 16px
      cursor: pointer
      +res-sm-max
        right: 0
    &.swiper-button-disabled
      display: none

  &__slider
    > .swiper-container
      > .swiper-button-prev,
      > .swiper-button-next
        display: none!important
    +res-md-min
      overflow: hidden
      // Variante di swiper
      > .swiper-container
        overflow: visible
        > .swiper-wrapper
          > .swiper-slide
            &.swiper-slide-active
              .pagebuilder-slide
                &__image-box
                  +transform(scale(1))

.pagebuilder-slide
  position: relative
  +res-md-min
    height: 480px
  +res-sm-max
    height: 180px
  &__image-box
    position: absolute
    top: 0
    height: 100%
    left: 0
    right: 0
    overflow: hidden
    +res-md-min
      +transform(scale(.8))
      +transition(transform .5s)
  &__image
    position: absolute
    max-width: none
    top: 0
    height: 100%
    left: -999px
    right: -999px
    margin: 0 auto
