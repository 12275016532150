@import 'public/styles/sass/abstracts'

.prodotto-carrello
  display: flex
  // align-items: center
  &__image-box
    width: 98px
    height: 98px

  &__content
    flex: 1
    padding: 15px 0 10px 16px

  &__cols
    display: flex
    align-items: flex-end
    > *
      flex: 1
      &:last-child
        text-align: right

  &__image
    display: block
    max-height: 98px
    margin: 0 auto

  &__title
    +typoTitle6
    text-transform: uppercase
    color: $neutrals-dark
    margin-bottom: 5px

  &__size
    +typoSmallText
    color: $neutrals-dark

  &__fullprice
    +typoSmallText
    text-decoration: line-through
    color: $neutrals-grey-4

  &__finalprice
    +typoRegularText
    font-weight: 700
    color: $neutrals-dark
