// Shortcuts 

@mixin clearfix
    &::after
        content: ""
        display: block
        clear: both

@mixin vertical-align()
    position: absolute
    top: 50%
    @include transform(translateY(-50%))

// Grid 

@mixin flexcol-part($value)
    width: 100
    min-height: 1px
    padding-right: 15px
    padding-left: 15px
    -webkit-box-flex: 0
    -ms-flex: 0 0 $value
    flex: 0 0 $value
    max-width: $value
    position: relative

@mixin flexcol($total)
    @for $i from 1 to $total - 1
        .col-#{$i}-#{$total}
            +flexcol-part($i / $total * 100%)
        +res-sm-min
            .col-sm-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-md-min
            .col-md-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-lg-min
            .col-lg-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)
        +res-xl-min
            .col-xl-#{$i}-#{$total}
                +flexcol-part($i / $total * 100%)


// Utilities 

@mixin placeholder-style
    &::-webkit-input-placeholder
        @content
    &::-moz-placeholder
        @content
    &:-ms-input-placeholder
        @content
    &:-moz-placeholder
        @content
    &::placeholder
        @content

@mixin color-icon($value)
    fill: $value
    &.icon
        &--strokes
            stroke: $value

@mixin color-stroke($value)
    stroke: $value

@mixin style-icon($size, $color)
    display: block
    width: $size
    height: $size
    +color-icon($color)

@mixin custom-row($gap)
    margin-left: -(calc(#{$gap}/2))
    margin-right: -(calc(#{$gap}/2))
    > *
        padding-right: calc(#{$gap}/2)
        padding-left: calc(#{$gap}/2)

@mixin multiline-ellipsis($lines, $line-height)
    height: $lines * $line-height // fallback per IE
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: $lines
    -webkit-box-orient: vertical

@mixin image-cover
    overflow: hidden
    > img, > .lazyload-wrapper > img
        display: block
        position: absolute
        top: 50%
        +translateY(-50%)
        left: -999px
        right: -999px
        margin: 0 auto
        max-width: none
        min-width: 100%
        min-height: 100%
        object-fit: cover

@mixin wcontainer
    margin-left: auto
    margin-right: auto
    padding-left: $wcont-padding
    padding-right: $wcont-padding
    max-width: $wcont-width

@mixin scrollbar-style
    &::-webkit-scrollbar
        background: $neutrals-grey-1
        width: 6px

    &::-webkit-scrollbar-thumb
        background: $neutrals-grey-3
        +border-radius(3px)

// Vendors Support 

@mixin vendors-support($prop, $value)
    -webkit-#{$prop}: $value
    -moz-#{$prop}: $value
    -ms-#{$prop}: $value
    -o-#{$prop}: $value
    #{$prop}: $value

@mixin columns($value)
    @include vendors-support(columns, $value)

@mixin box-shadow($value)
    @include vendors-support(box-shadow, $value)

@mixin border-radius($value)
    @include vendors-support(border-radius, $value)

@mixin border-top-left-radius($value)
    @include vendors-support(border-top-left-radius, $value)

@mixin border-top-right-radius($value)
    @include vendors-support(border-top-right-radius, $value)

@mixin border-bottom-left-radius($value)
    @include vendors-support(border-bottom-left-radius, $value)

@mixin border-bottom-right-radius($value)
    @include vendors-support(border-bottom-right-radius, $value)

@mixin transform($value)
    @include vendors-support(transform, $value)

@mixin transition($value)
    @include vendors-support(transition, $value)

@mixin translateX($value)
    @include vendors-support(transform, translateX($value))

@mixin translateY($value)
    @include vendors-support(transform, translateY($value))

@mixin rotate($value)
    @include vendors-support(transform, rotate($value))

@mixin filter($value)
    @include vendors-support(filter, $value)


// Media Queries 

@mixin res-xs-max
    @media (max-width: $res-xs-max)
        @content

@mixin res-xs-min
    @media (min-width: $res-xs-max)
        @content

@mixin res-sm-min
    @media (min-width: $res-sm-min)
        @content

@mixin res-sm-max
    @media (max-width: $res-sm-max)
        @content

@mixin res-md-min
    @media (min-width: $res-md-min)
        @content

@mixin res-md-max
    @media (max-width: $res-md-max)
        @content

@mixin res-md-only
    @media (max-width: $res-md-max) and (min-width: $res-md-min)
        @content

@mixin res-lg-min
    @media (min-width: $res-lg-min)
        @content

@mixin res-lg-max
    @media (max-width: $res-lg-max)
        @content

@mixin res-xl-min
    @media (min-width: $res-xl-min)
        @content

@mixin desktop
    @media (min-width: $res-sm-min)
        @content

@mixin until-desktop
  @media (max-width: $res-sm-max)
    @content

@mixin tablet
    @media (min-width: $res-sm-min) and (max-width: $res-sm-max)
        @content

@mixin mobile
    @media (max-width: $res-xs-max)
        @content

@mixin landscape
    @media screen and (orientation: landscape) 
        @content

@mixin portrait
    @media screen and (orientation: portrait) 
        @content