@import 'public/styles/sass/abstracts'

.cart
  &__alert
    display: none
    margin-bottom: 20px
    +mobile
      display: flex
  &__num-prodotti
    display: none
    color: $neutrals-grey-3
    margin-bottom: 20px
    +mobile
      +typoTitle6
      display: block
  &__content
    margin-left: auto
    margin-right: auto
    max-width: 944px
    .righe-carrello
      margin-bottom: 50px
  &__total
    +typoSmallText
    font-weight: 700
    text-align: right
    margin-bottom: 80px
    +mobile
      display: none
    &__label
      margin-right: 30px
  &__link
    text-align: right
    +mobile
      display: none
    & > *
      &:first-child
        margin-right: 20px
  &__empty
    +typoRegularText
    margin-bottom: 300px
  &__sticky
    display: none
    position: fixed
    flex-direction: column
    bottom: 0
    width: 100%
    background: white
    z-index: 100
    padding: $wcont-padding
    +box-shadow(-4px 0px 12px rgba(105, 100, 94, 0.1))
    +mobile
      display: flex
    &__total
      +typoRegularPrompt
      display: flex
      justify-content: space-between
      font-weight: 500
      margin-bottom: 10px
    &__cta
      width: 100%
