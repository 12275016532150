@import 'public/styles/sass/abstracts'

.page404
  &__back
    margin-top: -50px // Compensa gli 80px di margin della head della pagina
  &__products
    margin-top: 80px
    .prodotto-card
      margin-left: auto
      margin-right: auto
