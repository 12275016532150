.u-hidden-desktop
  +res-md-min
    display: none!important
.u-hidden-mobile
  +res-sm-max
    display: none!important

.u-align-center
  text-align: center
.u-align-center-desktop
  +res-md-min
    text-align: center
.u-align-center-mobile
  +res-sm-max
    text-align: center
  
.u-align-left
  text-align: left
.u-align-left-desktop
  +res-md-min
    text-align: left
.u-align-left-mobile
  +res-sm-max
    text-align: left

.u-align-right
  text-align: right
.u-align-right-desktop
  +res-md-min
    text-align: right
.u-align-right-mobile
  +res-sm-max
    text-align: right
