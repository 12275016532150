@import 'public/styles/sass/abstracts'

.form-seleziona-centro-checkout
  color: $neutrals-dark
  &__section
    &:not(:first-child)
      padding-top: 50px
      border-top: 1px solid $neutrals-grey-2
      margin-top: 50px
      +res-sm-max
        padding-top: 60px
        margin-top: 60px
    
  &__submit
    text-align: center
    .button
      margin-top: 50px
      margin-bottom: 32px
      +res-sm-max
        width: 100%
  &__error
    +typoLargePrompt
    text-align: center