=typoHighlight
  font-family: $font-primary
  font-size: 60px
  font-weight: 700
  line-height: 60px
  letter-spacing: 0.02em

=typoHighlightSmall
  font-family: $font-primary
  font-size: 50px
  font-weight: 700
  line-height: 60px
  letter-spacing: 0.02em

=typoLargeQuote
  font-family: $font-secondary
  font-size: 45px
  font-weight: 700
  line-height: 60px

=typoRegularQuote
  font-family: $font-secondary
  font-size: 35px
  font-weight: 700
  line-height: 46.66px

=typoSmallQuote
  font-family: $font-secondary
  font-size: 20px
  font-weight: 700
  line-height: 26.66px

=typoTinyQuote
  font-family: $font-secondary
  font-size: 18px
  font-weight: 700
  line-height: 24px

=typoTitle1
  font-family: $font-primary
  font-size: 44px
  font-weight: 700
  line-height: 48.4px
  letter-spacing: 0.03em

=typoTitle2
  font-family: $font-primary
  font-size: 36px
  font-weight: 700
  line-height: 45px
  letter-spacing: 0.02em

=typoTitle3
  font-family: $font-primary
  font-size: 32px
  font-weight: 700
  line-height: 35px
  letter-spacing: 0.02em

=typoTitle4
  font-family: $font-primary
  font-size: 24px
  font-weight: 700
  line-height: 30px
  letter-spacing: 0.02em

=typoTitle5
  font-family: $font-primary
  font-size: 20px
  font-weight: 700
  line-height: 30.24px
  letter-spacing: 0.01em

=typoTitle6
  font-family: $font-primary
  font-size: 14px
  font-weight: 700
  line-height: 21.17px
  letter-spacing: 0.01em

=typoLargePrompt
  font-family: $font-primary
  font-size: 24px
  font-weight: 400
  line-height: 36px

=typoRegularPrompt
  font-family: $font-primary
  font-size: 16px
  font-weight: 400
  line-height: 24.19px

=typoSmallPrompt
  font-family: $font-primary
  font-size: 14px
  font-weight: 400
  line-height: 21.17px

=typoLargeText
  font-family: $font-tertiary
  font-size: 20px
  font-weight: 400
  line-height: 27.28px
  
=typoRegularText
  font-family: $font-tertiary
  font-size: 16px
  font-weight: 400
  line-height: 21.82px

=typoSmallText
  font-family: $font-tertiary
  font-size: 14px
  font-weight: 400
  line-height: 19.6px

=typoMicro
  font-family: $font-tertiary
  font-size: 12px
  font-weight: 400
  line-height: 16.37px

=typoLabel
  font-family: $font-tertiary
  font-size: 10px
  font-weight: 700
  line-height: 13.64px
