@import 'public/styles/sass/abstracts'

.trattamento-card-big
  position: relative
  background-color: $neutrals-grey-1
  padding: 30px
  display: flex
  &__close
    position: absolute
    top: 30px
    right: 30px
    cursor: pointer
    svg
      +style-icon(24px, $color-black)
  &__image-box
    position: relative
    margin-right: 16px
    flex: 0 0 1
    width: 35%
    overflow: hidden
    min-height: 340px
    &--video
      cursor: pointer
  &__image
    position: absolute
    top: 0
    height: 100%
    left: -9999px
    right: -9999px
    margin: 0 auto
    max-width: none
  &__video-icon
    position: absolute
    top: 50%
    left: 0
    right: 0
    +translateY(-50%)
    margin: 0 auto
    +style-icon(119px, $color-white)
    z-index: 1
  &__content
    display: flex
    flex-direction: column
    flex: 1
  &__title
    +typoTitle4
    margin-bottom: 8px
  &__abstract
    +typoRegularText
    margin-bottom: 30px
  &__description
    +typoRegularText
    margin-bottom: 30px
  &__details
    +typoRegularText
    margin-bottom: 30px
  &__detail-value
    font-weight: 700
  &__buttons
    margin-top: auto
    display: flex
    justify-content: flex-end
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 16px