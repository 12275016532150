@import 'public/styles/sass/abstracts'

.box-help-contatti
  background: $neutrals-grey-1
  padding: 50px 0
  &__title
    +typoTitle3
    margin-bottom: 30px
    +res-sm-max
      +typoTitle4
  &__subtitle
    +typoTitle5
    margin-bottom: 30px
  &__description
    +typoRegularText