@import 'public/styles/sass/abstracts'

.centri-estetici-template
  &.page
    padding-bottom: 0
    .page
      &__head
        +res-md-min
          display: none
    
  .centri-estetici-form
    padding: 16px 20px
    +res-md-min
      // height desktop gestita in layout.sass
      display: flex
      align-items: center
    .form-field
      margin-right: 10px
      width: 248px
      +res-sm-max
        width: 100%
        &:first-child
          margin-bottom: 16px
    &__submit
      margin-left: auto
      +res-sm-max
        display: none

  .geolocation
    &__mobile
      display: none
      +res-sm-max
        display: block
      position: absolute
      top: 10px
      right: 10px
      padding: 12px
      background-color: $color-white

  .storelocator
    +res-md-min
      // height desktop gestito in layout.sass
      display: flex
      
    .hide-mobile
      +res-sm-max
        display: none

    &__tabs
      display: none
      +res-sm-max
        display: flex
        > * 
          flex: 1 0 0
      .button
        background-color: $neutrals-grey-3
        color: $color-white

        &.active
          background-color: $neutrals-grey-1
          color: $neutrals-dark

    &__panel
      +res-md-min
        width: 324px
      &__head
        +typoRegularText
        font-weight: 700
        padding: 20px

      &__list
        overflow-y: auto
        +scrollbar-style

    &__map
      position: relative
      +res-md-min
        width: calc(100% - 324px)
      +res-sm-max
        height: 546px
        // max-height gestita da layout.sass

  .centro-card-mobile
    margin-top: -150px
