@import 'public/styles/sass/abstracts'

.form-select
  &__control
    border: none!important
    background: $neutrals-grey-1!important
    color: $neutrals-grey-4
    width: 100%
    height: 48px
    outline: none!important
    box-shadow: none!important
    min-height: 0!important
    +border-radius(0!important)
    font-size: 14px
    font-family: $font-tertiary
    padding: 20px 12px 8px!important
  &__indicator-separator
    display: none!important
  &__dropdown-indicator
    margin-top: -8px!important
    svg
      +style-icon(24px, $neutrals-dark)
  &__value-container
    padding: 0!important
  &__single-value
    margin: 0!important
  &__menu
    margin: 0!important
    z-index: 2 !important
    +border-radius(0!important)
    +box-shadow(none!important)
  &__menu-list
    padding: 0!important
    +scrollbar-style
  &__option
    font-size: 16px!important
    padding: 13px 16px!important
    height: 48px
    color: $neutrals-dark!important
    cursor: pointer!important
    font-family: $font-tertiary!important
    background: $neutrals-grey-1!important
    &:hover
      background: $neutrals-grey-2!important
      color: $neutrals-dark!important
    &--is-selected
      background: $neutrals-grey-3!important
      color: $color-white!important
    &--is-focused
      color: $neutrals-dark!important
      outline: none!important
      background: $neutrals-grey-1!important

  &.form-field__input--no-placeholder // tolgo il padding lasciato per la label
    .form-select
      &__control
        padding-top: 8px!important
      &__dropdown-indicator
        margin-top: 0!important
