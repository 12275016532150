@import 'public/styles/sass/abstracts'

.myaccount-layout
  &.page
    padding-bottom: 0 // override del padding di page default
  +res-md-min
    min-height: 80vh
    display: flex

  &__back
    +res-md-min
      display: none
    +res-sm-max
      display: flex
      align-items: center
      margin: 30px 0 54px
      &__icon
        margin-right: 16px
        svg
          +style-icon(24px, currentColor)
      &__label
        +typoTitle4
    
  &__sidebar
    background: $neutrals-grey-1
    +res-md-min
      padding: 80px $wcont-padding 40px
      width: 33.33%
    +res-lg-min
      width: 25%
    @media screen and (min-width: $wcont-width)
      padding-left: calc(50vw - #{calc(#{$wcont-width} / 2)} + #{$wcont-padding})
      width: calc(50vw - #{$wcont-width * 0.25} + #{$wcont-padding})
    +res-sm-max
      background: $color-white
      position: fixed
      // top gestito da layout.sass
      bottom: 0
      overflow-y: auto
      +scrollbar-style
      left: 0
      width: 100%
      +translateX(-100%)
      +transition(transform .5s)
      z-index: 5500
      padding: 30px $wcont-padding
      &--open
        +translateX(0)
    
  &__main
    +res-md-min
      width: 66.66%
      padding: 80px $wcont-padding 40px 56px
    +res-lg-min
      width: 75%
    @media screen and (min-width: $wcont-width)
      padding-right: calc(50vw - #{calc(#{$wcont-width} / 2)} + #{$wcont-padding})
      width: calc(50vw + #{$wcont-width * 0.25} + #{$wcont-padding})
    +res-sm-max
      padding: 0 $wcont-padding

.myaccount-sidebar
  &__title
    +typoTitle3
    margin-bottom: 50px
    +res-md-max
      +typoTitle4
  &__close
    position: absolute
    top: 12px
    right: 16px
    cursor: pointer
    svg
      +style-icon(24px, $color-black)
    +res-md-min
      display: none

