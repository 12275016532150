@import 'public/styles/sass/abstracts'

.box-marketing
  position: relative
  +res-md-min
    max-width: 979px
    padding: 0 16px 87px
    margin: 0 auto
  &--center
    .box-marketing
      &__content
        text-align: center
        &__cta
          align-self: center
    
  &__content
    background: $neutrals-grey-1
    color: $neutrals-dark
    +res-md-min
      width: 82%
      min-height: 370px
      padding: 73px 60px
      display: flex
      flex-direction: column
      justify-content: center
    +res-sm-max
      padding: 50px 16px 200px
      text-align: center
    &__title
      font-size: 24px
      line-height: 30px
      font-weight: bold
      margin-bottom: 20px
    &__text
      font-size: 14px
      line-height: 21px
    &__cta
      margin-top: 24px
      +res-md-min
        align-self: flex-start
  &__image-box
    +res-md-min
      position: absolute
      width: 36%
      bottom: 0
    +res-sm-max
      margin: -125px auto 0
      width: 275px
  &--l
    .box-marketing
      &__content
        +res-md-min
          margin-left: auto
          padding-left: calc(18% + 60px)
      &__image-box
        +res-md-min
          left: $wcont-padding
  &--r
    .box-marketing
      &__content
        +res-md-min
          margin-right: auto
          padding-right: calc(18% + 60px)
      &__image-box
        +res-md-min
          right: $wcont-padding
    

  