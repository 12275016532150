@import 'public/styles/sass/abstracts'

.box-hero
  position: relative
  height: 100vh
  background-size: cover
  background-position: center center
  &__content
    position: absolute
    top: 50%
    +translateY(-50%)
    left: 0
    right: 0
    margin: 0 auto
    padding: 16px
    max-width: $wcont-width
    text-align: center
    color: $color-white
  &__title
    +typoTitle1
    text-transform: uppercase
    margin-bottom: 8px
  &__description
    font-family: $font-primary
    font-size: 20px
    font-weight: 600
    letter-spacing: 0.02em
  &__breadcrumbs
    position: absolute
    top: 0
    left: 0
    right: 0




