@import 'public/styles/sass/abstracts'

.toolbar
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  padding: 0 2rem
  width: 100%
  height: $toolbar-height
  background: #fff
  z-index: 4000
  +box-shadow(0px 1px 3px 0px $neutrals-grey-3)
  &__user
    +typoTitle5
  &__right
    margin-left: auto
    &__anteprima
      background: $color-primary
    &__caricamento
      cursor: none
  button
    height: 4rem
    line-height: 1rem
  .button-div
    display: inline
    margin-left: 15px
  .button    
    padding: 1rem 2rem
    &:not(:last-child)
      margin-right: 1rem
  +res-sm-max
    .button
      &:not(:last-child)
        display: none

.visibilita
  &--true
    color: green
  &--false
    color: red
  &--text
    font-size: 18px

.info
  vertical-align: middle
  display: inline-block
  margin-left: 5px
  svg
    width: 20px
    height: 20px

.tooltip
  display: inline
  z-index: 7100
  margin-top: 15px

@media print
  .toolbar
    display: none
  
  