@import 'public/styles/sass/abstracts'
  
.categoria-detail
  &__breadcrumbs
    margin-top: 40px
    margin-bottom: 80px
    +mobile
      margin-top: 20px
      margin-bottom: 50px
  &__title
    +typoTitle1
    margin-bottom: 30px
    text-transform: uppercase
    +mobile
      margin-bottom: 12px
      font-size: 24px
      line-height: 30px
  &__description
    +typoRegularText
    margin-bottom: 92px
    +mobile
      margin-bottom: 62px
      font-size: 14px
      line-height: 19px
  // &__listing
    

