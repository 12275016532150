@import 'public/styles/sass/abstracts'

.pre-carrello
  position: fixed
  top: 0
  right: 0
  display: flex
  flex-direction: column
  width: 100%
  max-width: 400px
  height: 100%
  opacity: 0
  +translateX(100%)
  background: $neutrals-grey-1
  z-index: 5000
  padding: 30px
  +transition($transition-duration ease-in-out)
  +box-shadow(-4px 0px 12px rgba(105, 100, 94, 0.1))
  +mobile
    max-width: 100%
  &--open
    opacity: 1
    +translateX(0)
  ::-webkit-scrollbar
    width: 6px
  ::-webkit-scrollbar-track
    background: $neutrals-grey-1
    margin: 0 0 25px 0
  ::-webkit-scrollbar-thumb
    background-color: $neutrals-grey-3
    +border-radius(4px)
  &__close
    cursor: pointer
    width: 24px
    height: 24px
    position: absolute
    top: 30px
    right: 30px
    +color-icon($neutrals-dark)
  &__title
    +typoTitle5
    color: $neutrals-dark
    margin-bottom: 20px
  &__alert
    margin-bottom: 40px
  &__num-prodotti
    +typoTitle6
    color: $neutrals-grey-3
    margin-bottom: 20px
  &__prodotti
    overflow-y: auto
    article
      margin-bottom: 20px
  &__footer
    width: 100%
    margin-top: auto
  &__total
    +typoLargeText
    display: flex
    justify-content: space-between
    color: $neutrals-dark
    margin-top: 20px
    margin-bottom: 40px
    &__price
      font-weight: 700
  &__link
    display: flex
    justify-content: space-between
