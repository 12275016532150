@import 'public/styles/sass/abstracts'

.linea-home-card
  position: relative
  max-width: 272px // dipende dal contesto, considerare di rimuoverlo
  display: block
  margin: 0 auto
  overflow: hidden
  &__content    
    position: absolute
    bottom: 0
    left: 0
    right: 0
    padding: 20px    
  &__image-box
    position: relative
    height: 355px
    +image-cover
  &__image
    z-index: -2
    &--hover
      z-index: -1
      opacity: 0
      +transition(opacity .8s)
  &__title
    font-family: $font-primary
    font-size: 30px
    font-weight: 700
    line-height: 30px
    text-transform: uppercase
    text-align: center
    color: #fff
  &:hover
    .linea-home-card
      &__image--hover
        opacity: 1
