@import 'public/styles/sass/abstracts'

.preheader
  position: fixed
  left: 0
  right: 0
  padding: 0 48px
  background: $accent-default
  height: $preheader-height-desktop
  overflow: hidden
  display: flex
  flex-direction: column
  justify-content: center
  +res-sm-max
    height: $preheader-height-mobile
    padding-left: 16px
  &__close
    position: absolute
    top: calc(50% - 8px)
    right: 16px
    cursor: pointer
    svg
      width: 16px
      height: 16px
      display: block
      +color-icon(white)
  &__content
    +typoRegularText
    font-weight: 500
    color: white
    text-align: center
    +res-md-max
      +typoMicro
    +res-sm-max
      text-align: left
      +multiline-ellipsis(2, 16.37px)
