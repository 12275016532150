@import 'public/styles/sass/abstracts'

.filters
  +res-md-min
    margin-bottom: 50px
  +res-sm-max
    margin-bottom: 20px

  &__head
    +res-md-min
      display: flex
      align-items: flex-start

  &__filters
    display: flex
    align-items: center
    flex-wrap: wrap
    > *
      margin-bottom: 10px
      &:not(:last-child)
        margin-right: 16px
      +res-sm-max
        &:first-child
          width: 100%
          justify-content: flex-start
          margin-right: 0
    +res-md-min
      flex: 1

  &__orders
    flex: 0 0 280px
    margin-left: 16px
    display: flex
    align-items: center
    +res-sm-max
      display: none
    > *
      flex: 1
    &__label
      flex: 0 0 auto
      +typoRegularText
      margin-right: 10px

  &__toggle-button
    padding-top: 6px
    height: 48px
    .button__label
      min-width: 120px
      text-align: left

  &__overlay
    +res-md-min
      display: none
    +res-sm-max
      position: fixed
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: rgba($neutrals-dark, .6)
      z-index: 99

  &__tendina
    position: relative
    padding: 0 30px // il padding top-bottom è compensato dal margin degli elementi
    background-color: $neutrals-grey-1
    +res-md-min
      +transition(max-height .5s)
      max-height: 0
      overflow-y: auto
      +scrollbar-style
      &--open
        max-height: 500px

    +res-sm-max
      position: fixed
      top: 0
      bottom: 0
      right: 0
      z-index: 5000
      width: 100%
      max-width: 440px
      +transition(transform .5s)
      +translateX(100%)
      padding: 54px 16px
      overflow-y: auto
      +scrollbar-style
      &--open
        +translateX(0)

  &__close
    position: absolute
    top: 24px
    right: 20px
    display: block
    cursor: pointer
    svg
      +style-icon(16px, $neutrals-dark)
    +res-md-min
      display: none

  &__item
    margin: 30px 0
    +res-sm-max
      margin-top: 0
    &--order
      +res-md-min
        display: none

  &__item-title
    +typoRegularText
    font-weight: 700

  &__value
    margin-top: 16px

  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      +custom-row(16px)
      > *
        width: 25%

  .form-field__label
    +typoRegularText
    font-weight: 700

  .form-radio__options
    flex-direction: column
