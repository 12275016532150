@import 'public/styles/sass/abstracts'

.il-tuo-ordine
  display: flex
  flex-direction: column
  padding: 50px 56px
  background: $neutrals-grey-1
  color: $neutrals-dark
  +res-sm-max
    padding: $wcont-padding
  &--open
    +res-sm-max
      padding-bottom: 0
      .il-tuo-ordine__main
        display: block
  &__trigger
    +typoMicro
    display: none
    align-items: center
    +res-sm-max
      display: flex
    &__total
      +typoSmallText
      font-weight: 700
      margin-left: auto
    .icon
      width: 24px
      height: 24px
      margin-right: 10px
      +color-icon(currentColor)
  &__mobile-divider
    margin-top: 12px
  &__main
    +res-sm-max
      display: none
  &__mobile-divider
    +res-sm-max
      display: block
    display: none
  &__title
    +typoTitle5
    margin-bottom: 50px
    +res-sm-max
      display: none
  &__promo-accordion
    padding-bottom: 20px
    .accordion__content
      padding-top: 10px
  &__promo-code
    display: flex
    align-items: center
    > *
      flex: 1
      &:last-child
        max-width: 80px
        margin-left: 8px
    .form-input
      background: white
  &__subtotale
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    +typoSmallText
  &__promozione
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    +typoSmallText
    font-weight: 700
    color: $color-primary
  &__spedizione
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    +typoSmallText
  &__totale
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    +typoRegularText
    font-weight: 700
  &__prodotti
    article
      margin-bottom: 30px
  &__alert
    margin-bottom: 20px
  .accordion
    &__placeholder
      +typoSmallText
      font-weight: 700
  .divider
    margin-bottom: 20px
