@import 'public/styles/sass/abstracts'

.page
  padding-bottom: 80px

  &__head
    margin-bottom: 80px
    +res-sm-max
      margin-bottom: 60px

  .breadcrumbs
    margin-top: 40px
    margin-bottom: 80px
    +res-sm-max
      margin-top: 20px
      margin-bottom: 30px

  &__title
    +typoTitle1
    text-transform: uppercase
    margin-bottom: 30px
    +res-sm-max
      +typoTitle4
      margin-bottom: 12px
      
  &__description
    +typoRegularText
