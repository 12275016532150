@import 'public/styles/sass/abstracts'

.prodotto-checkout
  display: flex
  align-items: center
  &__image-box
    width: 60px
    height: 60px

  &__content
    flex: 1
    padding: 0 0 0 20px
  &__cols
    display: flex
    > *
      flex: 2
      &:last-child
        flex: 1
        text-align: right

  &__image
    display: block
    max-height: 60px
    margin: 0 auto

  &__title
    +typoTitle6
    text-transform: uppercase
    color: $neutrals-dark

  &__size, &__quantity
    +typoSmallText
    color: $neutrals-dark

  &__size
    margin-bottom: 5px

  &__fullprice
    +typoSmallText
    text-decoration: line-through
    color: $neutrals-grey-4
  
  &__finalprice
    +typoRegularText
    font-weight: 700
    color: $neutrals-dark
