@import 'public/styles/sass/abstracts'

.banner-landing
    &__item
      background: $accent-default
      min-height: 579px
      display: flex
      +mobile
        flex-direction: column
        min-height: 158px
    &__content
        margin: 0 auto
        padding: 0 19.5px
      
    &__title
      color: #ffffff
      text-align: center
      padding: 65px 0 20px 0
      +typoTitle1
      +mobile
        +typoTitle4
        padding: 20px 0 10px 0

      
    &__description
        color: #ffffff
        text-align: center
        font-family: $font-primary
        font-size: 18px
        font-weight: 500
        line-height: 27.22px
        +mobile
          +typoRegularPrompt
          padding-bottom: 20px
    &__image
      display: none
      +mobile
        display: block
        